import "./policy.scss";
import { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect"

import Header from "../../components/header";
import Footer from "../../components/footer"
import Licenses from "../../components/licenses";

export default function Policy() {
  const [showLicense, setShowlicense] = useState(false)
  
  return (
    <>
      <BrowserView>
        <div id="policy-web-page">
          <Header/>

          <div id="main">
            <div id="policy-header">Privacy Policy</div>

            <div id="policy-lastupdated">
              Last Updated: September 30, 2024<br/><br/>

              This Privacy Policy explains how Detach collects, uses, 
              and protects your personal information when you use our Services.
            </div>

            <div className="policy-header">1. Information We Collect</div>
            <div className="policy-info">
              <strong>Personal Information:</strong> We may collect personal information that you provide directly, such as your name, email address, and payment information.
            </div>

            <div className="policy-header">2. How We Use Your Information</div>
            <div className="policy-info">
              We may use your information for various purposes, including:

              <ul>
                <li>To provide and maintain our Services.</li>
                <li>To notify you about changes to our Services.</li>
                <li>To respond to your inquiries and support requests.</li>
              </ul>
            </div>

            <div className="policy-header">3. Data Sharing</div>
            <div className="policy-info">
              We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:

              <ul>
                <li>With service providers who assist us in operating our Services.</li>
                <li>To comply with legal obligations or respond to lawful requests.</li>
              </ul>
            </div>

            <div className="policy-header">4. Security</div>
            <div className="policy-info">
              We take reasonable measures to protect your personal information from unauthorized access or 
              disclosure. However, no method of transmission over the Internet or electronic storage is 
              100% secure.
            </div>

            <div className="policy-header">5. Your Rights</div>
            <div className="policy-info">
              Depending on your jurisdiction, you may have certain rights regarding your personal 
              information, including the right to access, correct, or delete your information.
            </div>

            <div className="policy-header">6. Changes to This Privacy Policy</div>
            <div className="policy-info">
              We may update this Privacy Policy from time to time. We will notify you of any changes by 
              posting the new policy on our website.
            </div>

            <div className="policy-header">7. Contact Us</div>
            <div className="policy-info">
              If you have any questions about this Privacy Policy, please contact us at <a href="mailto:detach@geottuse.com">detach@geottuse.com</a>.
            </div>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {showLicense && (
            <div id="hidden-box" onClick={() => {
              setShowlicense(false)
            }}>
              {showLicense && <Licenses/>}
            </div>
          )}
        </div>
      </BrowserView>
      <MobileView>
        <div id="policy-mobile-page">
          <Header/>

          <div id="main">
            <div id="policy-header">Privacy Policy</div>

            <div id="policy-lastupdated">
              Last Updated: September 30, 2024<br/><br/>

              This Privacy Policy explains how Detach collects, uses, 
              and protects your personal information when you use our Services.
            </div>

            <div className="policy-header">1. Information We Collect</div>
            <div className="policy-info">
              <strong>Personal Information:</strong> We may collect personal information that you provide directly, such as your name, email address, and payment information.
              <br/><br/>
              <strong>Usage Data:</strong> We may collect information about how you use our Services, including IP address, browser type, and access times.
            </div>

            <div className="policy-header">2. How We Use Your Information</div>
            <div className="policy-info">
              We may use your information for various purposes, including:

              <ul>
                <li>To provide and maintain our Services.</li>
                <li>To notify you about changes to our Services.</li>
                <li>To respond to your inquiries and support requests.</li>
                <li>To analyze usage trends and improve our Services.</li>
              </ul>
            </div>

            <div className="policy-header">3. Data Sharing</div>
            <div className="policy-info">
              We do not sell or rent your personal information to third parties. We may share your information in the following circumstances:

              <ul>
                <li>With service providers who assist us in operating our Services.</li>
                <li>To comply with legal obligations or respond to lawful requests.</li>
              </ul>
            </div>

            <div className="policy-header">4. Security</div>
            <div className="policy-info">
              We take reasonable measures to protect your personal information from unauthorized access or 
              disclosure. However, no method of transmission over the Internet or electronic storage is 
              100% secure.
            </div>

            <div className="policy-header">5. Your Rights</div>
            <div className="policy-info">
              Depending on your jurisdiction, you may have certain rights regarding your personal 
              information, including the right to access, correct, or delete your information.
            </div>

            <div className="policy-header">6. Changes to This Privacy Policy</div>
            <div className="policy-info">
              We may update this Privacy Policy from time to time. We will notify you of any changes by 
              posting the new policy on our website.
            </div>

            <div className="policy-header">7. Contact Us</div>
            <div className="policy-info">
              If you have any questions about this Privacy Policy, please contact us at <a href="mailto:detach@geottuse.com">detach@geottuse.com</a>.
            </div>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {showLicense && (
            <div id="hidden-box" onClick={() => {
              setShowlicense(false)
            }}>
              {showLicense && <Licenses/>}
            </div>
          )}
        </div>
      </MobileView>
    </>
  )
}
