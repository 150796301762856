import "./dashboard.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoIosNotificationsOutline, IoIosAdd, IoMdClose, IoIosMove, IoIosArrowUp, IoIosArrowDown, IoIosClose, IoIosPeople, IoMdFolder } from "react-icons/io";
import { AiOutlineUserAdd, AiOutlineLoading3Quarters, AiFillProject, AiOutlineClose } from "react-icons/ai";
import { FaRegStar, FaStar, FaArrowLeft, FaChevronDown, FaChevronUp, FaArrowRight, FaUser, FaRegFolderOpen, FaClock, FaCheck, FaCaretDown, FaCaretRight } from "react-icons/fa";
import { FaRegFolderClosed, FaCircleCheck, FaRegClock, FaArrowDownLong, FaCirclePlus } from "react-icons/fa6";
import { IoLogOutOutline, IoAddCircleOutline, IoCalendarNumberOutline, IoCalendarOutline, IoArrowBackSharp } from "react-icons/io5";
import { MdFormatListBulleted, MdOutlineTextFields, MdEventNote, MdOutlineClose, MdEdit, MdWorkOutline, MdOutlineTask } from "react-icons/md";
import { BsPercent } from "react-icons/bs";
import { FiZoomIn, FiZoomOut, FiEdit2 } from "react-icons/fi";
import { GoTasklist } from "react-icons/go";
import { CiClock1, CiViewList, CiEdit } from "react-icons/ci";
import { GrFormPrevious, GrFormNext, GrUserWorker } from "react-icons/gr";
import { CgNotes } from "react-icons/cg";
import { getNotifications, getUserInfo, getUsage, getDesktopApp, readedIntro, getProgress, getNotes, isExceedUsage, checkIfSubscribed, createCheckout, getUsageRecords, togglePlan, requestFeature } from "../../apis/user"
import { getSidebarChoices, addChoice, moveInBetween, moveChoice, seeChoiceItems, checkValidBetween, removeParent, checkDeletable, deleteChoice, postponeTask, completedTask } from "../../apis/choice"
import { createNote, seeDesktopNote, saveNote, removeNote, seeAllNotes, saveAllNotes } from "../../apis/note"
import { postComment, deleteComment, saveComment } from "../../apis/comment"

// components
import Usagerecords from "../../components/usagerecords";
import Licenses from "../../components/licenses"

const pricePlan = 2.99
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

const TimeFrameIcon = ({ size }) => {
  return (
    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: size, justifyContent: 'space-around', width: size }}>
      <div style={{ display: 'flex', flexDirection: 'row', height: '50%', justifyContent: 'space-between', width: '100%' }}>
        <IoCalendarNumberOutline style={{ color: 'black', height: size * 0.5, width: size * 0.5 }}/>
        <IoCalendarNumberOutline style={{ color: 'black', height: size * 0.5, width: size * 0.5 }}/>
      </div>
      <FaClock style={{ color: 'black', height: size * 0.5, width: size * 0.5 }}/>
    </div>
  )
}

export default function Dashboard() {
  const navigate = useNavigate()

  const [token, setToken] = useState('')
  const [name, setName] = useState('')
  const [numNotifications, setNumnotifications] = useState(0)
  const [numUsages, setNumusages] = useState(0.0)
  const [notification, setNotification] = useState({ show: false, list: [], loading: false })
  const [updateNumusages, setUpdatenumusages] = useState(false)
  const [showInactiveplan, setShowinactiveplan] = useState(false)

  // layouts
  const [progress, setProgress] = useState([])
  const [progressLoading, setProgressloading] = useState(false)

  const [allNotes, setAllnotes] = useState([])
  const [allNotesloading, setAllnotesloading] = useState(false)

  const [datas, setDatas] = useState([])
  const [dataLoading, setDataloading] = useState(false)

  const [visible, setVisible] = useState({})
  const [movingChoice, setMovingchoice] = useState({ active: false, data: null })
  const [notes, setNotes] = useState([])
  const [todolists, setTodolists] = useState([])
  const [tasks, setTasks] = useState([])
  const [schedules, setSchedules] = useState([])
  const [viewingChoice, setViewingchoice] = useState({ choiceId: 0 })

  // popup boxes
  const [introBox, setIntrobox] = useState({ show: false, step: 0 })
  const [paymentBox, setPaymentbox] = useState({ show: false, loading: false })
  const [addChoicebox, setAddchoicebox] = useState({ show: false, parentId: 0, data: null, dir: "", between: "" })
  const[ addFolder, setAddfolder] = useState({
    show: false, name: ''
  })
  const [addProject, setAddproject] = useState({ 
    show: false, name: '', 
    scheduled: null,
    timeType: '',
    start: {
      calendar: {
        header: "",
        dates: [
          {
            key: "calendar-row-0",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-1",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-2",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-3",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-4",
            row: [{}, {}, {}, {}, {}, {}, {}]
          }
        ],
        selectedDate: 0,
        monthNum: 0,
        yearNum: 0
      },
      time: {
        hour: 0,
        minute: 0,
        period: "am"
      }
    },
    end: {
      calendar: {
        header: "",
        dates: [
          {
            key: "calendar-row-0",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-1",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-2",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-3",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-4",
            row: [{}, {}, {}, {}, {}, {}, {}]
          }
        ],
        selectedDate: 0,
        monthNum: 0,
        yearNum: 0
      },
      time: {
        hour: 0,
        minute: 0,
        period: "am"
      }
    },
    errorMsg: "",
    loading: false
  })
  const [addTodolistitem, setAddtodolistitem] = useState({
    show: false, 
    isTodolist: false,
    type: '',
    name: "",
    todolistId: 0,
    timeType: '',
    otherData: {},
    start: {
      calendar: {
        header: "",
        dates: [
          {
            key: "calendar-row-0",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-1",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-2",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-3",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-4",
            row: [{}, {}, {}, {}, {}, {}, {}]
          }
        ],
        selectedDate: 0,
        monthNum: 0,
        yearNum: 0
      },
      time: {
        hour: 0,
        minute: 0,
        period: "am"
      }
    },
    end: {
      calendar: {
        header: "",
        dates: [
          {
            key: "calendar-row-0",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-1",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-2",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-3",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-4",
            row: [{}, {}, {}, {}, {}, {}, {}]
          }
        ],
        selectedDate: 0,
        monthNum: 0,
        yearNum: 0
      },
      time: {
        hour: 0,
        minute: 0,
        period: "am"
      }
    }
  })
  const [addNote, setAddnote] = useState({ 
    show: false, 
    parentId: 0,
    title: '', info: '',
    errorMsg: ''
  })
  const [addTodolist, setAddtodolist] = useState({
    show: false,
    name: "",
    list: [],
    errorMsg: "",
    loading: false
  })
  const [deletableBox, setDeletablebox] = useState({ show: false, errorMsg: "" })
  const [deleteChoicebox, setDeletechoicebox] = useState({ show: false, id: null, type: '', name: '', loading: false })
  const [postponeTaskbox, setPostponetaskbox] = useState({ 
    show: false, 
    id: 0, 
    name: '', 
    timeType: '',
    start: {
      calendar: {
        header: "",
        dates: [
          {
            key: "calendar-row-0",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-1",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-2",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-3",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-4",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-5",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-6",
            row: [{}, {}, {}, {}, {}, {}, {}]
          }
        ],
        selectedDate: 0,
        monthNum: 0,
        yearNum: 0
      },
      time: {
        hour: 0,
        minute: 0,
        period: "am"
      }
    },
    end: {
      calendar: {
        header: "",
        dates: [
          {
            key: "calendar-row-0",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-1",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-2",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-3",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-4",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-5",
            row: [{}, {}, {}, {}, {}, {}, {}]
          },
          {
            key: "calendar-row-6",
            row: [{}, {}, {}, {}, {}, {}, {}]
          }
        ],
        selectedDate: 0,
        monthNum: 0,
        yearNum: 0
      },
      time: {
        hour: 0,
        minute: 0,
        period: "am"
      }
    },
    errorMsg: "",
    loading: false
  })
  const [usageRecords, setUsagerecords] = useState({
    show: false,
    showUsage: false,
    planPaused: false,
    project: { use: 0, limit: 0 },
    todolist: { use: 0, limit: 0 },
    task: { use: 0, limit: 0 },
    loading: false
  })
  const [moveLoading, setMoveloading] = useState(false)

  const [seeNotebox, setSeenotebox] = useState({ show: false, id: -1, title: '', info: '' })
  const [seeAllnotesbox, setSeeallnotesbox] = useState({ show: false, list: [] })
  const [showExceedusagebox, setShowexceedusagebox] = useState({ show: false, nextPlan: "" })

  const [report, setReport] = useState(false)
  const [requestFeaturebox, setRequestfeaturebox] = useState({ show: false, input: '', isPrivate: false, loading: false })
  const [showLicenses, setShowlicenses] = useState(false)

  const [isTester, setIstester] = useState(false)

  const getScheduledStamp = info => {
    let scheduled = null
    let { selectedDate, monthNum, yearNum } = info.calendar
    let { hour, minute, period } = info.time
    let parseStr = months[monthNum] + " " + selectedDate + ", " + yearNum + " " + 
      (
        period === "am" ? 
          hour === 12 ? 0 : hour
          : 
          hour < 12 ? parseInt(hour) + 12 : hour
      )
      + ":" + minute

    if (selectedDate > 0) {
      const duestamp = selectedDate > 0 ? Date.parse(parseStr) : 0

      scheduled = duestamp / 1000
    }

    return scheduled
  }
  const getTheUserInfo = () => {
    const data = { token }

    getUserInfo(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then(async res => {
        if (res) {
          setIstester(res.isTester)

          if (res.readedIntro) {
            if (res.customerId || res.isTester) {
              getTheProgress()
              getTheNotes()
              getTheChoices()
            } else {
              setPaymentbox({ ...paymentBox, show: true })
              setProgressloading(false)
              setDataloading(false)
            }
          } else {
            setIntrobox({ ...introBox, show: true, step: 0 })
          }

          const status = res.planPaused.split(":")

          setName(res.name)

          if (status[0] === "true" && !res.isTester) {
            setShowinactiveplan(true)
          }
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const getTheUsage = () => {
    const data = { token, dataType: "" }

    getUsage(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res.msg === "upgradeRequired") {
          setNumusages(100)
        } else if (res.msg === "unlimited") {
          setNumusages(null)
        } else {
          setNumusages(res.msg)
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const readedTheIntro = () => {
    const data = { token }

    readedIntro(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setIntrobox({ ...introBox, show: false, step: 0 })
          setPaymentbox({ ...paymentBox, show: true })
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const isTheExceedUsage = type => {
    const data = { token, dataType: type }

    isExceedUsage(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          const { planPaused, over } = res

          if (!planPaused) {
            if (!over) {
              if (type === "Folder") {
                setAddchoicebox({ ...addChoicebox, show: false })
                setAddfolder({
                  ...addFolder,
                  show: true, name: ''
                })
              } else if (type === "Project") {
                setAddchoicebox({ ...addChoicebox, show: false })
                setAddproject({ 
                  ...addProject, show: true, name: "", 
                  startEnabled: false,
                  endEnabled: false
                })
              } else if (type === "Todolist") {
                setAddtodolist({ 
                  ...addTodolist, 
                  show: true, 
                  name: "",
                  list: []
                })
              } else if (type === "Task" || type == "Schedule") {
                setAddtodolistitem({ ...addTodolistitem, show: true, type, timeType: '', otherData: { parentId: viewingChoice.choiceId } })
              }
            } else {
              setAddchoicebox({ ...addChoicebox, show: false })
              setShowexceedusagebox({ ...showExceedusagebox, show: true })
            }
          } else {
            setShowinactiveplan(true)
          }
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          err.json().then(({ status }) => {
            switch (status) {

            }
          })
        }
      })
  }
  const checkTheIfSubscribed = async () => {
    const response = await checkIfSubscribed({ token })
    const data = await response.json()

    return data
  }
  const getTheUsageRecords = () => {
    const data = { token }

    getUsageRecords(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          if (res.usages) {
            setUsagerecords({
              ...usageRecords,
              show: true,
              showUsage: true,
              planPaused: res.planPaused,
              ...res.usages
            })
          } else {
            setUsagerecords({
              ...usageRecords,
              show: true,
              planPaused: res.planPaused
            })
          }
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          err.json().then(({ status }) => {
            switch (status) {
              case "unsubscribed":
                setPaymentbox({ ...paymentBox, show: true })

                break;
              default:
            }
          })
        }
      })
  }
  const getTheProgress = () => {
    setProgressloading(true)

    const data = { token }

    getProgress(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {          
          const { progress } = res
          const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

          progress.forEach(function (info) {
            // get end date
            let endDateInfo = new Date(info.end * 1000)

            let day = days[endDateInfo.getDay()]
            let month = months[endDateInfo.getMonth()]
            let date = endDateInfo.getDate()
            let year = endDateInfo.getFullYear()

            let hour = endDateInfo.getHours()
            let minute = endDateInfo.getMinutes()

            info.end = day + ", " + month + " " + date + ", " + year + " at " + 
            (hour > 12 ? hour - 12 : (hour < 10 ? hour > 0 ? "0" + hour : "12" : hour)) + 
            ":" + (minute < 10 ? "0" + minute : minute) + 
            (hour > 11 ? "pm" : "am")

            // get created date
            let createdDateInfo = new Date(info.created * 1000)

            day = days[createdDateInfo.getDay()]
            month = months[createdDateInfo.getMonth()]
            date = createdDateInfo.getDate()
            year = createdDateInfo.getFullYear()

            hour = createdDateInfo.getHours()
            minute = createdDateInfo.getMinutes()

            info.created = day + ", " + month + " " + date + ", " + year + " at " + 
            (hour > 12 ? hour - 12 : (hour < 10 ? hour > 0 ? "0" + hour : "12" : hour)) + 
            ":" + (minute < 10 ? "0" + minute : minute) + 
            (hour > 11 ? "pm" : "am")

            let startDateInfo = new Date(info.start * 1000)

            day = days[startDateInfo.getDay()]
            month = months[startDateInfo.getMonth()]
            date = startDateInfo.getDate()
            year = startDateInfo.getFullYear()

            hour = startDateInfo.getHours()
            minute = startDateInfo.getMinutes()

            info.start = day + ", " + month + " " + date + ", " + year + " at " + 
            (hour > 12 ? hour - 12 : (hour < 10 ? hour > 0 ? "0" + hour : "12" : hour)) + 
            ":" + (minute < 10 ? "0" + minute : minute) + 
            (hour > 11 ? "pm" : "am")
          })

          setProgress(progress)
          setProgressloading(false)
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          err.json().then(({ status }) => {
            switch (status) {
              case "unsubscribed":
                setProgressloading(false)
                setPaymentbox({ ...paymentBox, show: true })

                break;
            }
          })
        }
      })
  }
  const getTheNotes = () => {
    setAllnotesloading(true)

    const data = { token }

    getNotes(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          const { notes } = res

          notes.forEach(function (info) {
            // get created date
            let createdDateInfo = new Date(info.created * 1000)

            let day = days[createdDateInfo.getDay()]
            let month = months[createdDateInfo.getMonth()]
            let date = createdDateInfo.getDate()
            let year = createdDateInfo.getFullYear()

            let hour = createdDateInfo.getHours()
            let minute = createdDateInfo.getMinutes()

            info.created = day + ", " + month + " " + date + ", " + year + " at " + 
            (hour > 12 ? hour - 12 : (hour < 10 ? hour > 0 ? "0" + hour : "12" : hour)) + 
            ":" + (minute < 10 ? "0" + minute : minute) + 
            (hour > 11 ? "pm" : "am")
          })

          setAllnotes(notes)
          setAllnotesloading(false)
        }
      })
      .catch((err) => {

      })
  }
  const getTheChoices = () => {
    setDataloading(true)

    const data = { token }

    getSidebarChoices(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setDatas(res.datas)
          setDataloading(false)
          setVisible(res.visible)
          setUpdatenumusages(false)
          setMoveloading(false)
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          err.json().then(({ status }) => {
            switch (status) {
              case "unsubscribed":
                setDataloading(false)
                setPaymentbox({ ...paymentBox, show: true })

                break;
            }
          })
        }
      })
  }
  const addTheFolder = () => {
    const { name } = addFolder
    const { parentId, between, dir } = addChoicebox

    if (name) {
      const data = { token, parentId, type: 'Folder', name, between, dir }

      setAddfolder({ ...addFolder, loading: true })

      addChoice(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setAddfolder({ ...addFolder, show: false, name: '', errorMsg: '', loading: false })
            setUpdatenumusages(true)
          }
        })
        .catch((err) => {
          if (err.status === 400) {
            err.json().then(({ status }) => {
              switch (status) {
                case "nameTaken":
                  setAddfolder({ ...addFolder, errorMsg: "Can't enter the same folder name twice" })
              }
            })
          }
        })
    } else {
      setAddfolder({ ...addFolder, errorMsg: "Please enter the folder name" })
    }
  }
  const addTheProject = () => {
    const { name, start, end, timeType } = addProject
    const { parentId, between, dir } = addChoicebox

    if (name) {
      const data = { token, parentId, type: 'Project', name, between, dir, startScheduled: 0, endScheduled: 0 }
      let validDate = false, errorMsg = ""

      if (timeType) {
        if (timeType === "startenddatetime") {
          if (start.calendar.selectedDate > 0 && end.calendar.selectedDate > 0) {
            data.startScheduled = getScheduledStamp(start)
            data.endScheduled = getScheduledStamp(end)

            if (data.endScheduled - data.startScheduled > 0) {
              validDate = true
            } else {
              errorMsg = "Start date can't be after the end date"
            }
          } else {
            if (start.calendar.selectedDate === 0) {
              errorMsg = "Please select a start date"   
            } else {
              errorMsg = "Please select an end date"
            }
          }
        } else {
          if (end.calendar.selectedDate > 0) {
            data.endScheduled = getScheduledStamp(end)

            validDate = true
          } else {
            errorMsg = "Please select a due date"
          }
        }
      } else {
        validDate = true
      }

      if (validDate) {
        setAddproject({ ...addProject, loading: true })

        addChoice(data)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              cancelProject()
              setUpdatenumusages(true)
            }
          })
          .catch((err) => {
            if (err.status === 400) {
              err.json().then(({ status }) => {
                switch (status) {
                  case "nameTaken":
                    setAddproject({ ...addProject, errorMsg: "Can't enter the same project name twice" })
                }
              })
            }
          })
      } else {
        setAddproject({ ...addProject, errorMsg })
      }
    } else {
      setAddproject({ ...addProject, errorMsg: "Please enter the project name" })
    }
  }
  const addTheTodolist = () => {
    const { name, list } = addTodolist
    let emptyTask = false, tasks = []
    let validDate = true

    for (let k = 0; k < list.length; k++) {
      let { header, start, end, timeType } = list[k]

      if (!header) {
        emptyTask = true

        break;
      }

      if (timeType) {
        if (timeType === "startenddatetime") {
          if (start.calendar.selectedDate > 0 && end.calendar.selectedDate > 0) {
            tasks.push({
              header,
              startScheduled: getScheduledStamp(start),
              endScheduled: getScheduledStamp(end)
            })
          } else {
            if (start.calendar.selectedDate === 0) {
              setAddtodolist({ ...addTodolist, errorMsg: "One of the task's start date is not selected" })
            } else {
              setAddtodolist({ ...addTodolist, errorMsg: "One of the task's end date is not selected" })
            }

            validDate = false
          }
        } else {
          if (end.calendar.selectedDate > 0) {
            tasks.push({
              header,
              startScheduled: 0,
              endScheduled: getScheduledStamp(end)
            })
          } else {
            setAddtodolist({ ...addTodolist, errorMsg: "One of the task's due date is not selected" })

            validDate = false
          }
        }
      } else {
        tasks.push({
          header,
          startScheduled: 0,
          endScheduled: 0
        })
      }
    }

    if (validDate) {
      const { between, dir } = addChoicebox

      if (name && !emptyTask) {
        const data = { token, parentId: viewingChoice.choiceId, type: 'Todolist', name, tasks: JSON.stringify(tasks), between, dir }

        setAddtodolist({ ...addTodolist, loading: true })

        addChoice(data)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              setAddtodolist({ ...addTodolist, show: false, name: '', list: [], errorMsg: "", loading: false })
              getTheProgress()
              seeTheChoiceItems(viewingChoice)
            }
          })
          .catch((err) => {

          })
      } else {
        setAddtodolist({ 
          ...addTodolist, 
          errorMsg: !name ? "Enter the name for the Todo list" : "There are one or more empty tasks"
        })
      }
    }
  }
  const addTheTodolistItem = () => {
    const { name, start, end, todolistId, timeType, otherData } = addTodolistitem
    const { parentId } = otherData
    const { between, dir } = addChoicebox

    if (name) {
      const data = { 
        token, parentId, type: '', name, between, dir, 
        startScheduled: 0, endScheduled: 0
      }
      let validDate = false, errorMsg = ""

      if (timeType) {
        if (timeType === "startenddatetime") {
          if (start.calendar.selectedDate > 0 && end.calendar.selectedDate > 0) {
            data.startScheduled = getScheduledStamp(start)
            data.endScheduled = getScheduledStamp(end)

            if (data.endScheduled - data.startScheduled > 0) {
              validDate = true
            } else {
              errorMsg = "Start date can't be after the end date"
            }
          } else {
            if (start.calendar.selectedDate === 0) {
              errorMsg = "Please select a start date"
            } else {
              errorMsg = "Please select an end date"
            }
          }
        } else {
          if (end.calendar.selectedDate > 0) {
            data.endScheduled = getScheduledStamp(end)

            validDate = true
          } else {
            errorMsg = "Please select a due date"
          }
        }

        data.type = 'Schedule'
      } else {
        validDate = true
        data.type = 'Task'
      }

      if (validDate) {
        setAddtodolistitem({ ...addTodolistitem, loading: true })

        addChoice(data)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {              
              cancelTask()
              getTheProgress()
              seeTheChoiceItems(viewingChoice)
            }
          })
      } else {
        setAddtodolistitem({ ...addTodolistitem, errorMsg })
      }
    } else {
      setAddtodolistitem({ ...addTodolistitem, errorMsg: "Please enter the name for the new task" })
    }
  }
  const postponeTheTask = id => {
    let data = {}, errorMsg = "", validDate = true

    if (!postponeTaskbox.show) {
      data["choiceId"] = id
    } else {
      const { name, start, end, timeType } = postponeTaskbox
      
      data = { choiceId: postponeTaskbox.id, name, startScheduled: 0, endScheduled: 0 }
      validDate = false

      if (timeType) {
        if (timeType === "startenddatetime") {
          if (start.calendar.selectedDate > 0 && end.calendar.selectedDate > 0) {
            data.startScheduled = getScheduledStamp(start)
            data.endScheduled = getScheduledStamp(end)

            if (data.endScheduled - data.startScheduled > 0) {
              validDate = true
            } else {
              errorMsg = "Start date can't be after the end date"
            }
          } else {
            if (start.calendar.selectedDate === 0) {
              errorMsg = "Please select a start date"
            } else {
              errorMsg = "Please select an end date"
            }
          }
        } else {
          if (end.calendar.selectedDate > 0) {
            data.endScheduled = getScheduledStamp(end)

            validDate = true
          } else {
            errorMsg = "Pelase select a due date"
          }
        }
      } else {
        validDate = true
      }
    }

    if (validDate) {
      data["type"] = !postponeTaskbox.show ? "get" : "update"
      data["token"] = token

      setPostponetaskbox({ ...postponeTaskbox, loading: true })

      postponeTask(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            if (!postponeTaskbox.show) {
              const newInfo = {...postponeTaskbox}
              const { name, startStamp, endStamp } = res

              createCalendar("", "postpone", { id, name }, { startStamp, endStamp })
            } else {
              cancelPostponeTask()
              getTheProgress()
              seeTheChoiceItems(viewingChoice)
            }
          }
        })
        .catch((err) => {
          if (err.status === 400) {
            err.json().then(({ status }) => {
              switch (status) {
                case "nameTaken":
                  setPostponetaskbox({ ...postponeTaskbox, errorMsg: "Can't enter the same task name twice" })

                  break;
              }
            })
          }
        })
    } else {
      setPostponetaskbox({ ...postponeTaskbox, errorMsg })
    }
  }
  const createTheNote = () => {
    const { parentId, title, info } = addNote
    const data = { token, parentId, title, info }

    if (title && info) {
      createNote(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setAddnote({ ...addNote, show: false, title: '', info: '' })
            setUpdatenumusages(true)
          }
        })
        .catch((err) => {
          if (err.status === 400) {

          }
        })
    } else {
      setAddnote({ 
        ...addNote, 
        errorMsg: !title ? "Please enter a title for your note" : "Note is empty"
      })
    }
  }
  const createCalendar = (
    dir = "", // "", next, previous
    type, // todolist, task, postpone
    info, // (index of todolist) or (name of task) or (start/end calendar for project)
    oldstamp // scheduled task for > 0
  ) => {
    const calcCalendar = (dir, newInfo, oldstamp) => {
      const days = { "Sun": 0, "Mon": 1, "Tue": 2, "Wed": 3, "Thu": 4, "Fri": 5, "Sat": 6 }
      let date = new Date(), y = date.getFullYear(), m = date.getMonth()
      let newCalendar, newTime, scheduled = 0

      if (oldstamp !== null && !isNaN(oldstamp) && oldstamp > 0) {
        date = new Date(oldstamp)
      }

      let { monthNum, selectedDate, yearNum } = newInfo.calendar
      newTime = newInfo.time

      if (dir === "") { // no direction, just opened calendar
        monthNum = m
        yearNum = y

        newTime.hour = date.getHours() < 10 ? 
          "0" + date.getHours() 
          : 
          (
            date.getHours() > 12 ? (
              date.getHours() - 12 < 10 ? 
                "0" + (date.getHours() - 12) 
                : 
                date.getHours() - 12
            ) 
            : 
            date.getHours()
          )
        newTime.minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
        newTime.period = date.getHours() > 11 ? "pm" : "am"

        if ((type === "todolist" && scheduled > 0) || oldstamp !== null) {
          m = date.getMonth()
          y = date.getFullYear()

          monthNum = m
          yearNum = y
        }
      } else {
        if (dir === "next") {
          monthNum++

          if (monthNum > 11) {
            monthNum = 0
            yearNum++
          }
        } else {
          monthNum--

          if (monthNum < 0) {
            monthNum = 11
            yearNum--
          }
        }

        m = monthNum
        y = yearNum
      }

      let firstDay = new Date(y, m, 1), day, year
      let lastDay = new Date(y, m + 1, 0);
      let dateNum = 0

      firstDay = firstDay.toString().split("GMT")
      lastDay = lastDay.toString().split("GMT")

      firstDay = firstDay[0].split(" ")
      day = firstDay[0]
      year = firstDay[3]

      lastDay = lastDay[0].split(" ")
      lastDay.pop()
      lastDay.pop()
      lastDay.pop()

      firstDay = days[day]
      lastDay = parseInt(lastDay[2])

      newInfo.calendar.header = months[m] + " " + y
      newInfo.calendar.dates.forEach(function (info, rowIndex) {
        info.row.forEach(function (date, dateIndex) {
          info.row[dateIndex]["key"] = "calendar-row-" + rowIndex
          info.row[dateIndex]["date"] = -1

          if (rowIndex === 0) {
            if (dateIndex >= firstDay) {
              dateNum++

              info.row[dateIndex]["date"] = dateNum
              info.row[dateIndex]["unix"] = Date.parse(day + ", " + months[monthNum] + " " + dateNum + ", " + year)
            }
          } else if (dateNum < lastDay) {
            dateNum++

            info.row[dateIndex]["date"] = dateNum
            info.row[dateIndex]["unix"] = Date.parse(day + ", " + months[monthNum] + " " + dateNum + ", " + year)
          }
        })
      })

      newInfo.calendar.selectedDate = dir === "" ? date.getDate() : 0
      newInfo.calendar.monthNum = monthNum
      newInfo.calendar.yearNum = yearNum

      newTime.hour = date.getHours() < 10 ? 
        date.getHours() === 0 ? 
          "12"
          :
          "0" + date.getHours() 
        : 
        (
          date.getHours() > 12 ? (
            date.getHours() - 12 < 10 ? 
              "0" + (date.getHours() - 12) 
              : 
              date.getHours() - 12
          ) 
          : 
          date.getHours()
        )
      newTime.minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
      newTime.period = date.getHours() > 11 ? "pm" : "am"
      newInfo.time = newTime

      return newInfo
    }

    let newInfo

    switch (type) {
      case "project":
        let newAddproject = {...addProject}

        newAddproject.startEnabled = false
        newAddproject.endEnabled = false

        if (dir === "") { // opening calendar
          if (info.timeType) {
            if (info.timeType === "startenddatetime") {
              newAddproject.start = calcCalendar(dir, newAddproject.start, oldstamp)
              newAddproject.end = calcCalendar(dir, newAddproject.end, oldstamp)

              newAddproject.startEnabled = true
            } else {
              newAddproject.end = calcCalendar(dir, newAddproject.end, oldstamp)
            }

            newAddproject.endEnabled = true
            newAddproject.timeType = info.timeType
          }
        } else {
          if (info === "start") {
            newAddproject.start = calcCalendar(dir, newAddproject.start, oldstamp)
            newAddproject.startEnabled = true
          } else {
            newAddproject.end = calcCalendar(dir, newAddproject.end, oldstamp)
            newAddproject.startEnabled = true
          }
        }

        setAddproject(newAddproject)

        break;
      case "todolist": // info is index of todolist
        const newAddtodolist = {...addTodolist}

        newInfo = newAddtodolist.list[info.index]

        if (dir === "") { // opening calendar
          newInfo.startEnabled = false
          newInfo.endEnabled = false

          if (info.timeType) {
            if (info.timeType === "startenddatetime") {
              newInfo.start = calcCalendar(dir, newInfo.start, oldstamp)
              newInfo.end = calcCalendar(dir, newInfo.end, oldstamp)

              newInfo.startEnabled = true
            } else {
              newInfo.end = calcCalendar(dir, newInfo.end, oldstamp)
            }

            newInfo.endEnabled = true
            newInfo.timeType = info.timeType

            newAddtodolist.list[info.index] = newInfo
          } else {
            if (info === "start") {
              newInfo.start = calcCalendar(dir, newInfo.start, oldstamp)
              newInfo.startEnabled = true
            } else {
              newInfo.end = calcCalendar(dir, newInfo.end, oldstamp)
              newInfo.endEnabled = true
            }
          }
        } else {
          if (newInfo.timeType === "startenddatetime") {
            if (info.type === "start") {
              newInfo.start = calcCalendar(dir, newInfo.start, oldstamp)
            } else {
              newInfo.end = calcCalendar(dir, newInfo.end, oldstamp)
            }
          } else {
            newInfo.end = calcCalendar(dir, newInfo.end, oldstamp)
          }
        }

        newAddtodolist.list[info.index] = newInfo

        setAddtodolist(newAddtodolist)

        break;
      case "task":
        let newTask = {...addTodolistitem}
        let start, end

        if (dir === "") {
          newTask.start = calcCalendar(dir, newTask.start, oldstamp)
          newTask.end = calcCalendar(dir, newTask.end, oldstamp)
          newTask.timeType = info.timeType
        } else {
          if (info === "start") {
            newTask.start = calcCalendar(dir, newTask.start, oldstamp)
          } else {
            newTask.end = calcCalendar(dir, newTask.end, oldstamp)
          }
        }

        newTask.show = true

        setAddtodolistitem(newTask)

        break;
      case "postpone": // info is name of task
        let newPostponetaskbox = {...postponeTaskbox}

        if (dir === "") {
          newPostponetaskbox.id = info.id
          newPostponetaskbox.name = info.name

          if (oldstamp.startStamp > 0 || oldstamp.endStamp > 0) {
            if (oldstamp.startStamp > 0) {
              newPostponetaskbox.start = calcCalendar(dir, newPostponetaskbox.start, oldstamp.startStamp)
            }

            if (oldstamp.endStamp > 0) {
              newPostponetaskbox.end = calcCalendar(dir, newPostponetaskbox.end, oldstamp.endStamp)
            }

            if (oldstamp.startStamp > 0 && oldstamp.endStamp > 0) {
              newPostponetaskbox.timeType = "startenddatetime"
            } else {
              newPostponetaskbox.timeType = "duedatetime"
            }
          } else {
            if (info.timeType === "startenddatetime") {
              newPostponetaskbox.start = calcCalendar(dir, newPostponetaskbox.start, 0)
              newPostponetaskbox.end = calcCalendar(dir, newPostponetaskbox.end, 0)

              newPostponetaskbox.timeType = "startenddatetime"
            } else if (info.timeType === "duedatetime") {
              newPostponetaskbox.timeType = "duedatetime"

              newPostponetaskbox.end = calcCalendar(dir, newPostponetaskbox.end, 0)
            }
          }
        } else {
          if (info === "start") {
            newPostponetaskbox.start = calcCalendar(dir, newPostponetaskbox.start, oldstamp)
          } else {
            newPostponetaskbox.end = calcCalendar(dir, newPostponetaskbox.end, oldstamp)
          }
        }

        newPostponetaskbox.show = true

        setPostponetaskbox(newPostponetaskbox)

        break;
      default:
    }
  }
  const adjustDueTime = (
    timeType, 
    dir = null, 
    actionType, 
    type, 
    info // (index of task in todolist) or (start/end of project)
  ) => {
    const calcTime = newTime => {
      let { hour, minute, period } = newTime

      hour = parseInt(hour)
      minute = parseInt(minute)

      if (timeType === "hour") {
        hour = dir === "up" ? hour + 1 : hour - 1

        if (hour > 12) {
          hour = 1
        } else if (hour === 0) {
          hour = 12
        }
      } else if (timeType === "minute") {
        minute = dir === "up" ? minute + 1 : minute - 1

        if (minute > 59) {
          minute = 0
        } else if (minute === -1) {
          minute = 59
        }
      } else {
        period = period === "am" ? "pm" : "am"
      }

      newTime.hour = hour < 10 ? "0" + hour : hour
      newTime.minute = minute < 10 ? "0" + minute : minute
      newTime.period = period

      return newTime
    }

    let newTime

    switch (type) {
      case "project":
        const newAddproject = {...addProject}

        if (info === "start") {
          newTime = newAddproject.start.time
        } else {
          newTime = newAddproject.end.time
        }
        
        newTime = calcTime(newTime)

        if (info === "start") {
          newAddproject.start.time = newTime
        } else {
          newAddproject.end.time = newTime
        }

        setAddproject(newAddproject)

        break;
      case "todolist":
        const newAddtodolist = {...addTodolist}

        if (info.type === "start") {
          newTime = newAddtodolist.list[info.index].start.time
        } else {
          newTime = newAddtodolist.list[info.index].end.time
        }

        newTime = calcTime(newTime)

        if (info.type === "start") {
          newAddtodolist.list[info.index].start.time = newTime
        } else {
          newAddtodolist.list[info.index].end.time = newTime
        }

        setAddtodolist(newAddtodolist)

        break;
      case "task":
        const newAddtask = {...addTodolistitem}

        if (info === "start") {
          newTime = newAddtask.start.time
        } else {
          newTime = newAddtask.end.time
        }
        
        newTime = calcTime(newTime)

        if (info === "start") {
          newAddtask.start.time = newTime
        } else {
          newAddtask.end.time = newTime
        }

        setAddtodolistitem(newAddtask)

        break;
      case "postpone":
        const newPostponetaskbox = {...postponeTaskbox}

        if (info === "start") {
          newTime = newPostponetaskbox.start.time
        } else {
          newTime = newPostponetaskbox.end.time
        }

        newTime = calcTime(newTime)

        if (info === "start") {
          newPostponetaskbox.start.time = newTime
        } else {
          newPostponetaskbox.end.time = newTime
        }

        setPostponetaskbox(newPostponetaskbox)

        break;
      default:

    }
  }
  const deleteNote = index => {
    const { bullets } = addNote

    bullets.splice(index, 1)

    setAddnote({ ...addNote, bullets })
  }
  const removeTheParent = id => {
    const data = { token, id }

    removeParent(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {          
          setUpdatenumusages(true)
          setMovingchoice({ ...movingChoice, active: false, data: null })
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const seeAllTheNotes = () => {
    const data = { token, parentId: viewingChoice.choiceId }

    seeAllNotes(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setSeeallnotesbox({ ...seeAllnotesbox, show: true, list: res.list })
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          
        }
      })
  }
  const removeTheNote = (id, type, name) => {
    if (!deleteChoicebox.show) {
      setDeletechoicebox({ ...deleteChoicebox, show: true, id, type, name })
    } else {
      const data = { token, noteId: deleteChoicebox.id }

      removeNote(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setDeletechoicebox({ ...deleteChoicebox, show: false, id: 0, type: '', name: '', loading: false })
            setUpdatenumusages(true)
          }
        })
        .catch((err) => {
          if (err.status === 400) {

          }
        })
    }
  }
  const moveInTheBetween = info => {
    const { parentId } = info
    const { id } = movingChoice.data
    const data = { token, newParentId: parentId, newChild: info.id, movingId: id }

    moveInBetween(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setMovingchoice({ ...movingChoice, active: false, data: null })
          getTheChoices()
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          
        }
      })
  }
  const moveTheChoice = (data, rowIndex) => {
    if (!movingChoice.active) {
      const newData = { ...data, rowIndex }

      setMovingchoice({ ...movingChoice, active: true, data: newData })
    } else {
      const { choiceId, type, parentId } = movingChoice.data
      let info = { token, type, id: choiceId, newId: data.choiceId }
      let valid = true

      if (
        (type === "Task" && data.type === "Schedule")
        ||
        (type === "Schedule" && data.type === "Task")
        ||
        (type === "Note" && data.type === "Todolist")
        ||
        (type === "Todolist" && data.type === "Note")
        ||
        (
          JSON.stringify(movingChoice.data).includes("\"choiceId\":" + data.choiceId + ",")
          &&
          type !== "Note"
        )
      ) {
        valid = false
      }

      if (valid === true) {
        setMoveloading(true)

        moveChoice(info)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              setMovingchoice({ ...movingChoice, active: false, data: null })
              setViewingchoice(data)
              getTheChoices()
              seeTheChoiceItems(data.type === "Todolist" ? viewingChoice : data)

              if (type === "Note") {
                getTheNotes()
              }
            }
          })
          .catch((err) => {
            if (err.status === 400) {

            }
          })
      } else {
        setMovingchoice({ ...movingChoice, active: false, data: null })
      }
    }
  }
  const seeTheChoiceItems = data => {
    const dataInfo = { choiceId: data.choiceId }

    seeChoiceItems(dataInfo)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          const { notes, todolists, tasks, schedules } = res

          setNotes(notes)
          setTodolists(todolists)
          setTasks(tasks)
          setSchedules(schedules)
          setViewingchoice(data)
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          err.json().then(({ status }) => {

          })
        }
      })
  }
  const validChoiceMove = data => { // data to be replace by movingChoice.data
    const { choiceId, type, parentId, datas = null } = movingChoice.data

    return ( // list all allows inside
      data.choiceId !== parentId
      &&
      data.choiceId !== choiceId
    )
  }
  const theCheckDeletable = choiceData => {
    const { id, type, name } = choiceData
    const data = { token, id }

    checkDeletable(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          if (res.deletable) {
            deleteTheChoice(id, type, name)
          } else {
            seeTheChoiceItems(choiceData)
            setDeletablebox({ ...deletableBox, show: true, errorMsg: res.msg })
          }
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          err.json().then(({ status }) => {

          })
        }
      })
  }
  const deleteTheChoice = (id, type, name) => {
    if (!deleteChoicebox.show) {
      setDeletechoicebox({ ...deleteChoicebox, show: true, id, type, name })
    } else {
      const data = { token, id: deleteChoicebox.id }

      setDeletechoicebox({ ...deleteChoicebox, loading: true })

      deleteChoice(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            setDeletechoicebox({ ...deleteChoicebox, show: false, id: 0, type: '', name: '', loading: false })
            setUpdatenumusages(true)

            if (viewingChoice.choiceId === deleteChoicebox.id) {
              setViewingchoice({ choiceId: 0 })
            }
          }
        })
        .catch((err) => {
          if (err.status === 400) {

          }
        })
    }
  }
  const completedTheTask = id => {
    const data = { token, id }

    completedTask(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          getTheProgress()
          seeTheChoiceItems(viewingChoice)
        }
      })
  }
  const seeTheNote = id => {
    const data = { token, id }

    seeDesktopNote(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setSeenotebox({
            ...seeNotebox,
            show: true,
            id,
            title: res.title,
            info: res.info
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const saveTheNote = () => {
    const { id, title, info } = seeNotebox
    const data = { token, id, title, info }

    saveNote(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setSeenotebox({
            ...seeNotebox,
            show: false,
            id: -1, title: '', info: ''
          })
          seeTheChoiceItems(viewingChoice)
          getTheNotes()
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const saveAllTheNote = () => {
    const data = { token, parentId: viewingChoice.choiceId, list: JSON.stringify(seeAllnotesbox.list) }
    
    saveAllNotes(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setSeeallnotesbox({
            ...seeAllnotesbox,
            show: false, list: []
          })
          getTheNotes()
          seeTheChoiceItems(viewingChoice)
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }
  const displayDatas = (parentId, datas, start, rowIndex) => {
    if (datas) {
      return (
        (datas.map((data, index) => {
          return (
            <div key={data.key} style={{ 
              paddingLeft: rowIndex > 0 ? rowIndex + 10 : 0
            }}>
              <div
                className={
                  viewingChoice.choiceId !== data.choiceId ? 
                    "info-box" + (
                      movingChoice.active ? 
                        validChoiceMove(data) && viewingChoice.choiceId !== data.choiceId ? "-hover" : ""
                        :
                        ""
                    )
                    :
                    "info-box-selected"
                }
                onClick={(e) => {
                  if (!movingChoice.active) {
                    seeTheChoiceItems(data)
                  } else {
                    moveTheChoice(data, rowIndex)
                  }

                  e.stopPropagation()
                }}
              >
                <div className="info-box-row">
                  <div className="row">
                    <div className="info-box-leftactions">
                      {data.datas ? 
                        <div className="info-box-leftaction-icon" onClick={(e) => {
                          const newVisible = {...visible}
                          let blockInfo = newVisible[data.key]

                          blockInfo = blockInfo === "none" ? "block" : "none"

                          newVisible[data.key] = blockInfo

                          setVisible(newVisible)

                          e.stopPropagation()
                        }}>
                          {visible[data.key] === "block" ? 
                            <FaCaretDown style={{ height: '100%', width: '100%' }}/>
                            :
                            <FaCaretRight style={{ height: '100%', width: '100%' }}/>
                          }         
                        </div>
                        :
                        <div className="info-box-leftaction-icon"/>
                      }
                    </div>
                    <div className="info-headers">
                      <div className="info-header">
                        <div className="info-header-icon">
                          {data.type === "Folder" && (
                            visible[data.key] === "none" ? 
                              <IoMdFolder style={{ color: 'black', height: '100%', width: '100%' }}/>
                              :
                              <FaRegFolderOpen style={{ color: 'black', height: '100%', width: '100%' }}/>
                          )}
                          {data.type === "Project" && <AiFillProject style={{ color: 'black', height: '100%', width: '100%' }}/>}
                        </div>
                        <div className="column"><div className="info-header-name">{data.name}</div></div>
                      </div>
                    </div>
                  </div>
                  <div className="info-box-rightactions">
                    <div className="info-box-rightaction-icon" onClick={() => setAddchoicebox({ ...addChoicebox, show: true, parentId: data.choiceId, data, between: false, dir: "after" })}>
                      <FaCirclePlus style={{ height: '100%', width: '100%' }}/>
                    </div>

                    {(!movingChoice.active || movingChoice.data.choiceId === data.choiceId) && (
                      <div className="info-box-rightaction-icon" onClick={(e) => {
                        if (!movingChoice.active) {
                          moveTheChoice(data, rowIndex)
                        } else {
                          setMovingchoice({ ...movingChoice, active: false, data })
                        }

                        e.stopPropagation()
                      }}>
                        <IoIosMove style={{ height: '100%', width: '100%' }}/>
                      </div>
                    )}
                    
                    <div className="info-box-rightaction-icon" onClick={(e) => {
                      theCheckDeletable({ ...data, id: data.choiceId })

                      e.stopPropagation()
                    }}>
                      <IoMdClose style={{ height: '100%', width: '100%' }}/>
                    </div>
                  </div>
                </div>
              </div>

              {data.datas && (
                <div style={{ display: visible[data.key] }}>
                  {(data.datas) && displayDatas(data.choiceId, data.datas, false, rowIndex + 1)}
                </div>
              )}
            </div>
          )
        }))
      );
    }
  }
  const requestTheFeature = () => {
    if (!requestFeaturebox.show) {
      setRequestfeaturebox({
        ...requestFeaturebox,
        show: true,
        input: ''
      })
    } else {
      const { input, isPrivate } = requestFeaturebox

      if (input) {
        const data = { token, input, isPrivate }

        setRequestfeaturebox({ ...requestFeaturebox, loading: true })

        requestFeature(data)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              setRequestfeaturebox({
                ...requestFeaturebox,
                show: false,
                input: '',
                loading: false,
                errorMsg: ''
              })
            }
          })
          .catch((err) => {
            if (err.status === 400) {

            }
          })
      } else {
        setRequestfeaturebox({
          ...requestFeaturebox,
          errorMsg: "Request is empty"
        })
      }
    }
  }

  // cancel choices
  const cancelFolder = () => {
    setAddfolder({ ...addFolder, show: false, name: '' })
  }
  const cancelProject = () => {
    const newAddproject = {...addProject}
    const { start, end } = newAddproject

    start.calendar.selectedDate = 0
    end.calendar.selectedDate = 0

    setAddproject({ 
      ...addProject, show: false, name: '', timeType: '',
      start, end, errorMsg: ''
    })
  }
  const cancelTodolist = () => {
    setAddtodolist({ ...addTodolist,
      show: false,
      name: "",
      list: [],
      errorMsg: "",
      loading: false
    })
  }
  const cancelTask = () => {
    setAddtodolistitem({ ...addTodolistitem, show: false, timeType: '', name: '', loading: false, errorMsg: '' })
  }
  const cancelPostponeTask = () => {
    setPostponetaskbox({ ...postponeTaskbox, show: false, id: 0, timeType: '', loading: false, errorMsg: '' })
  }

  const logoutUser = () => {
    localStorage.clear()

    navigate("/auth")
  }

  const createTheCheckout = () => {
    setPaymentbox({ ...paymentBox, loading: true })
    
    const data = { token }

    createCheckout(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          if (res.msg) {
            window.location = res.msg
          }
        }
      })
      .catch((err) => {

      })
  }
  const toggleThePlan = () => {
    setUsagerecords({
      ...usageRecords,
      loading: true
    })

    const data = { token: localStorage.getItem("token") }

    togglePlan(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setUsagerecords({
            ...usageRecords,
            planPaused: !usageRecords.planPaused,
            loading: false
          })
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }

  useEffect(() => {
    const initialize = () => {
      const userToken = localStorage.getItem("token")

      if (userToken) {
        setToken(userToken)
      } else {
        navigate("/")
      }
    }

    initialize()
  }, [])

  useEffect(() => {
    if (token) getTheUserInfo()
  }, [token])

  useEffect(() => {
    if (updateNumusages) {
      getTheUsage()
      getTheProgress()
      getTheNotes()
      getTheChoices()
      seeTheChoiceItems(viewingChoice)
    }
  }, [updateNumusages])

  useEffect(() => {
    if (seeAllnotesbox.list.length > 0) {
      const { list } = seeAllnotesbox

      for (let k = 0; k < list.length; k++) {
        let textarea = document.getElementsByClassName("seeallnotes-note")[k]

        textarea.style.height = textarea.scrollHeight + "px";

        textarea.addEventListener('input', () => {
          textarea.style.height = 'auto';
          textarea.style.height = textarea.scrollHeight + "px";
        });
      }
    }
  }, [seeAllnotesbox.list.length])

  return (
    <div id="dashboard-page">
      {(!progressLoading && progress.length > 0) && (
        <div id="tasks-container">
          {!progressLoading ? 
              <>
                <div id="progress-list-header">Your TASKS/SCHEDULES</div>

                <div id="progress-list">
                  {progress.map((info, index) => (
                    <div className="progress" key={info.key} style={{ marginRight: progress.length - 1 === index ? 4 : 0 }}>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          {info.setAction && (
                            <>
                              <div className="progress-header-action" onClick={() => completedTheTask(info.id)}>
                                <FaCircleCheck style={{ color: info.completed ? 'blue' : 'white', height: '100%', width: '100%' }}/>
                              </div>
                              <div className="progress-header-action" onClick={() => postponeTheTask(info.id)}>
                                <FiEdit2 style={{ height: '100%', width: '100%' }}/>
                              </div>
                              <div className="progress-header-action" onClick={() => {
                                if (!movingChoice.active) {
                                  moveTheChoice(info)
                                } else {
                                  setMovingchoice({ ...movingChoice, active: false, data: info })
                                }
                              }}>
                                <IoIosMove style={{ height: '100%', width: '100%' }}/>
                              </div>
                              <div className="progress-header-action" onClick={() => theCheckDeletable({ ...info, type: "Task", name: info.taskName })}>
                                <AiOutlineClose style={{ height: '100%', width: '100%' }}/>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="progress-task-header">
                        <strong>{info.type.toUpperCase()}</strong>: {info.taskName}
                      </div>
                      <div className="progress-mini-headers">
                        {info.parent && <div className="progress-mini-header"><strong>{info.parent["type"]}:</strong> {info.parent["name"]}</div>}

                        {(info.endStamp && info.endStamp !== 0) && (
                          info.startStamp !== 0 ? // start and end
                            <>
                              <div className="progress-mini-header">
                                <strong>Scheduled from</strong> {info.start} <strong>to</strong> {info.end}
                              </div>
                              <div className="progress-mini-header">
                                <strong>Due in:</strong> {info.endStamp}
                              </div>
                            </>
                            :
                            <div className="progress-mini-header">
                              <strong>Due in:</strong> {info.endStamp} <strong>on</strong> {info.end}
                            </div>
                        )}

                        <div className="progress-mini-header"><strong>Created on:</strong> {info.created}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            :
            <div id="loading-container" style={{ height: '50%' }}>
              <div id="loading-icon" style={{ height: 30, width: 30 }}>
                <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
              </div>
            </div>
          }
        </div>
      )}
      <div id="container" style={{ height: progress.length > 0 ? '84%' : '100%' }}>
        <div id={"sidebar" + (movingChoice.active ? "-hover" : "")}>
          <div id="sidebar-top" onClick={() => {
            if (movingChoice.active) {
              const { choiceId } = movingChoice.data

              removeTheParent(choiceId)
            } else {
              setViewingchoice({})
            }
          }}>
            {!dataLoading ? 
              datas.length > 0 ? 
                <div id="data-container">
                  {displayDatas(null, datas, true, 0)}

                  <div className="row">
                    <div id="add-button" onClick={() => setAddchoicebox({ ...addChoicebox, show: true, parentId: 0, data: { type: 'Folder' }, between: false })}>
                      <div className="column"><div id="add-button-icon"><IoIosAdd style={{ height: '100%', width: '100%' }}/></div></div>
                    </div>
                  </div>
                </div>
                :
                <div id="options">
                  <div id="options-header">Start with a</div>

                  <div className="row">
                    <div className="option" onClick={async e => {
                      const { subscribed } = await checkTheIfSubscribed()

                      if (subscribed) {
                        e.stopPropagation()

                        setAddchoicebox({ ...addChoicebox, parentId: 0, data: { type: 'Folder' }, between: false })
                        setAddfolder({ ...addFolder, show: true })
                      } else {
                        setPaymentbox({ ...paymentBox, show: true })
                      }
                    }}>
                      <div className="column"><div className="option-add"><IoIosAdd style={{ height: '100%', width: '100%' }}/></div></div>
                      <div className="column"><div className="option-header">A folder</div></div>
                      <div className="column">
                        <div className="option-icon">
                          <FaRegFolderClosed style={{ color: 'black', height: '100%', width: '100%' }}/>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="option" onClick={async e => {
                      const { subscribed } = await checkTheIfSubscribed()

                      if (subscribed) {
                        e.stopPropagation()

                        setAddchoicebox({ ...addChoicebox, parentId: 0, data: { type: 'Project' }, between: false })
                        setAddproject({ ...addProject, show: true })
                      } else {
                        setPaymentbox({ ...paymentBox, show: true })
                      }
                    }}>
                      <div className="column"><div className="option-add"><IoIosAdd style={{ height: '100%', width: '100%' }}/></div></div>
                      <div className="column"><div className="option-header">A project</div></div>
                      <div className="column">
                        <div className="option-icon">
                          <AiFillProject style={{ color: 'black', height: '100%', width: '100%' }}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              :
              <div id="loading-box" style={{ height: 20 }}>
                <div id="loading-container">
                  <div id="loading-icon" style={{ height: 20, width: 20 }}>
                    <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                  </div>
                </div>
              </div>
            }
          </div>

          <div id="bottom-actions">
            <div className="row">
              <div className="bottom-action" onClick={() => window.open(process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL, "_blank")}>
                <div className="bottom-action-header">Customer Portal</div>
              </div>
            </div>
            <div className="row">
              <div className="row">
                {(isTester === true) && (
                  <>
                    <div className="bottom-action" onClick={() => document.getElementById("download-app").click()}>
                      <div className="column"><div className="bottom-action-header">Get app</div></div>
                    </div>
                    <a id="download-app" href="https://www.joindetach.com/api/static/detach.dmg" download style={{ display: 'none' }}/>
                  </>
                )}
                <div className="bottom-action" onClick={() => getTheUsageRecords()}>
                  <div className="column"><div className="bottom-action-icon"><BsPercent style={{ height: '100%', width: '100%' }}/></div></div>
                  <div className="column"><div className="bottom-action-header">Usage</div></div>
                </div>
                <div className="bottom-action" onClick={() => setRequestfeaturebox({ ...requestFeaturebox, show: true, input: '', isPrivate: true })}>
                  <div className="bottom-action-header">Request feature</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="bottom-action" onClick={() => logoutUser()}>
                  <div className="bottom-action-icon"><IoLogOutOutline style={{ height: '100%', width: '100%' }}/></div>
                  <div className="bottom-action-header">Log-Out</div>
                </div>
                <div className="bottom-action" onClick={() => window.location = "/terms"}>
                  <div className="bottom-action-header">Terms</div>
                </div>
                <div className="bottom-action" onClick={() => window.location = "/policy"}>
                  <div className="bottom-action-header">Policy</div>
                </div>
                <div className="bottom-action" onClick={() => setShowlicenses(true)}>
                  <div className="bottom-action-header">Licenses</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="main">
          {(datas.length > 0 && viewingChoice.choiceId) ?  
            <>
              <div id="container-header">
                <strong>{viewingChoice.type.toUpperCase()}: </strong>
                {viewingChoice.name}
              </div>
              
              <div className={"container-item" + (movingChoice.active ? "-hover" : "")} onClick={() => {
                if (movingChoice.active) {
                  moveTheChoice(viewingChoice)
                }
              }}>
                <div className="list-header">
                  <div className="list-row" onClick={(e) => {
                    setAddnote({ ...addNote, show: true, parentId: viewingChoice.choiceId, data: viewingChoice, between: false, dir: "after" })

                    e.stopPropagation()
                  }}>
                    <div className="column">{notes.length} NOTE(s)</div>

                    <div className="column">
                      <div className="list-actions">
                        {notes.length > 0 && (
                          <div className="list-action" onClick={(e) => {
                            seeAllTheNotes()

                            e.stopPropagation()
                          }}>
                            <FiEdit2 style={{ height: '60%', width: '60%' }}/>
                          </div>
                        )}

                        <div className="list-action" onClick={(e) => {
                          setAddnote({ ...addNote, show: true, parentId: viewingChoice.choiceId, data: viewingChoice, between: false, dir: "after" })

                          e.stopPropagation()
                        }}>
                          <IoIosAdd style={{ height: '80%', width: '80%' }}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {notes.length > 0 && (
                  <div className="container-item-row">
                    {notes.map((note, index) => (
                      <div className="container-item-choice" key={note.key} onClick={(e) => {
                        if (movingChoice.active) {
                          moveTheChoice(note)
                        } else {
                          seeTheNote(note.choiceId)
                        }

                        e.stopPropagation()
                      }}>
                        <div className="row">
                          <div className="action-icons">
                            <div className="action-icon" onClick={() => seeTheNote(note.choiceId)}>
                              <FiEdit2 style={{ height: '100%', width: '100%' }}/>
                            </div>
                            <div className="action-icon" onClick={(e) => {
                              if (!movingChoice.active) {
                                moveTheChoice(note)
                              } else {
                                setMovingchoice({ ...movingChoice, active: false, data: note })
                              }

                              e.stopPropagation()
                            }}>
                              <IoIosMove style={{ height: '100%', width: '100%' }}/>
                            </div>
                            <div className="action-icon" onClick={(e) => {
                              removeTheNote(note.choiceId, "Note", note.title)

                              e.stopPropagation()
                            }}>
                              <IoMdClose style={{ height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        </div>
                        <div className="choice-headers">
                          <div className="column">
                            <div className="choice-icon">
                              <CgNotes style={{ color: 'black', height: '80%', width: '80%' }}/>
                            </div>
                          </div>
                          <div className="column"><div className="choice-header"><strong>TITLE</strong>: {note.title}</div></div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className={"container-item" + (movingChoice.active ? "-hover" : "")}>
                <div className="list-header">
                  <div className="list-row" onClick={(e) => {
                    setAddchoicebox({ ...addChoicebox, between: false, dir: "after" })
                    isTheExceedUsage("Todolist")

                    e.stopPropagation()
                  }}>
                    <div className="column">{todolists.length} TO-DO LIST(s)</div>

                    <div className="column">
                      <div className="list-actions">
                        <div className="list-action">
                          <IoIosAdd style={{ height: '100%', width: '100%' }}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {todolists.length > 0 && (
                  <div className="container-item-row">
                    {todolists.map((todolist, index) => (
                      <div className="container-item-choice" key={todolist.key} onClick={(e) => {
                        if (movingChoice.active) {
                          moveTheChoice(todolist)
                        }
      
                        e.stopPropagation()
                      }}>
                        <div className="row">
                          <div className="action-icons">
                            <div className="action-icon" onClick={() => theCheckDeletable({ ...todolist, id: todolist.choiceId, type: "Todolist" })}>
                              <IoMdClose style={{ height: '100%', width: '100%' }}/>
                            </div>
                            <div className="action-icon" onClick={(e) => {
                              if (!movingChoice.active) {
                                moveTheChoice(todolist)
                              } else {
                                setMovingchoice({ ...movingChoice, active: false, data: todolist })
                              }

                              e.stopPropagation()
                            }}>
                              <IoIosMove style={{ height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        </div>
                        <div className="choice-headers">
                          <div className="column">
                            <div className="choice-icon">
                              <CiViewList style={{ color: 'black', height: '80%', width: '80%' }}/>
                            </div>
                          </div>
                          <div className="column"><div className="choice-header"><strong>TITLE:</strong> {todolist.name}</div></div>
                        </div>
                        <div className="todolist-tasks">
                          {todolist.tasks.map((task, index) => (
                            <div className="task" key={task.key}>
                              <div className="task-header">
                                <div className="task-header-icon">
                                  {task.type === "Task" && <MdOutlineTask style={{ height: '100%', width: '100%' }}/>}
                                  {task.type === "Schedule" && <CiClock1 style={{ height: '100%', width: '100%' }}/>}
                                </div>
                                
                                <strong>{index + 1}:</strong> {task.name}
                              </div>

                              <div className="task-actions">
                                <div className="task-action" onClick={() => completedTheTask(task.choiceId)}>
                                  <FaCircleCheck style={{ color: task.completed ? 'blue' : 'grey', height: '100%', width: '100%' }}/>
                                </div>
                                <div className="task-action" onClick={() => postponeTheTask(task.choiceId)}>
                                  <FiEdit2 style={{ height: '100%', width: '100%' }}/>
                                </div>
                                <div className="task-action" onClick={() => theCheckDeletable({ ...task, id: task.choiceId, type: "Task" })}>
                                  <MdOutlineClose style={{ height: '100%', width: '100%' }}/>
                                </div>
                                {(!movingChoice.active || movingChoice.data.choiceId === task.choiceId) && (
                                  <div className="task-action" onClick={(e) => {
                                    if (!movingChoice.active) {
                                      moveTheChoice(task)
                                    } else {
                                      setMovingchoice({ ...movingChoice, active: false, data: task })
                                    }

                                    e.stopPropagation()
                                  }}>
                                    <IoIosMove style={{ height: '100%', width: '100%' }}/>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="row">
                          <div className="todolist-task-add" onClick={(e) => setAddtodolistitem({ ...addTodolistitem, isTodolist: true, show: true, type: '', timeType: '', otherData: { parentId: todolist.choiceId } })}>
                            <div className="column"><div className="task-add-header">Add</div></div>
                            <div className="column"><div className="task-add-icon"><IoIosAdd style={{ height: '100%', width: '100%' }}/></div></div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className={"container-item" + (movingChoice.active ? "-hover" : "")} onClick={() => {
                if (movingChoice.active) {
                  moveTheChoice(viewingChoice)
                }
              }}>
                <div className="list-header">
                  <div className="list-row" onClick={(e) => {
                    setAddchoicebox({ ...addChoicebox, parentId: viewingChoice.choiceId, data: viewingChoice, between: false, dir: "after" })
                    isTheExceedUsage("Task")

                    e.stopPropagation()
                  }}>
                    <div className="column">{tasks.length} TASK(s)</div>

                    <div className="column">
                      <div className="list-actions">
                        <div className="list-action">
                          <IoIosAdd style={{ height: '100%', width: '100%' }}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                {tasks.length > 0 && (
                  <div className="container-item-row">
                    {tasks.map((task, index) => (
                      <div className="container-item-choice" key={task.key} onClick={(e) => {
                        if (movingChoice.active) {
                          moveTheChoice(task)
                        }
      
                        e.stopPropagation()
                      }}>
                        <div className="row">
                          <div className="action-icons">
                            <div className="action-icon" onClick={() => completedTheTask(task.choiceId)}>
                              <FaCircleCheck style={{ color: task.completed ? 'blue' : 'grey', height: '100%', width: '100%' }}/>
                            </div>
                            <div className="action-icon" onClick={() => postponeTheTask(task.choiceId)}>
                              <FiEdit2 style={{ height: '100%', width: '100%' }}/>
                            </div>
                            <div className="action-icon" onClick={() => theCheckDeletable({ ...task, id: task.choiceId, type: "Task" })}>
                              <IoMdClose style={{ height: '100%', width: '100%' }}/>
                            </div>
                            
                            {(!movingChoice.active || movingChoice.data.choiceId == task.choiceId) && (
                              <div className="action-icon" onClick={(e) => {
                                if (!movingChoice.active) {
                                  moveTheChoice(task)
                                } else {
                                  setMovingchoice({ ...movingChoice, active: false, data: task })
                                }

                                e.stopPropagation()
                              }}>
                                <IoIosMove style={{ height: '100%', width: '100%' }}/>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="choice-headers">
                          <div className="column">
                            <div className="choice-icon">
                              <MdOutlineTask style={{ color: 'black', height: '80%', width: '80%' }}/>
                            </div>
                          </div>
                          <div className="column"><div className="choice-header"><strong>TITLE:</strong> {task.name}</div></div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className={"container-item" + (movingChoice.active ? "-hover" : "")} onClick={() => {
                if (movingChoice.active) {
                  moveTheChoice(viewingChoice)
                }
              }}>
                <div className="list-header">
                  <div className="list-row" onClick={(e) => {
                    setAddchoicebox({ ...addChoicebox, parentId: viewingChoice.choiceId, data: viewingChoice, between: false, dir: "after" })
                    isTheExceedUsage("Schedule")

                    e.stopPropagation()
                  }}>
                    <div className="column">{schedules.length} SCHEDULE(s)</div>

                    <div className="column">
                      <div className="list-actions">
                        <div className="list-action">
                          <IoIosAdd style={{ height: '100%', width: '100%' }}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {schedules.length > 0 && (
                  <div className="container-item-row">
                    {schedules.map((schedule, index) => (
                      <div className="container-item-choice" key={schedule.key} onClick={(e) => {
                        if (movingChoice.active) {
                          moveTheChoice(schedule)
                        }
      
                        e.stopPropagation()
                      }}>
                        <div className="row">
                          <div className="action-icons">
                            <div className="action-icon" onClick={() => completedTheTask(schedule.choiceId)}>
                              <FaCircleCheck style={{ color: schedule.completed ? 'blue' : 'grey', height: '100%', width: '100%' }}/>
                            </div>
                            <div className="action-icon" onClick={() => postponeTheTask(schedule.choiceId)}>
                              <FiEdit2 style={{ height: '100%', width: '100%' }}/>
                            </div>
                            <div className="action-icon" onClick={() => theCheckDeletable({ ...schedule, id: schedule.choiceId, type: "Schedule" })}>
                              <IoMdClose style={{ height: '100%', width: '100%' }}/>
                            </div>
                            <div className="action-icon" onClick={(e) => {
                              if (!movingChoice.active) {
                                moveTheChoice(schedule)
                              } else {
                                setMovingchoice({ ...movingChoice, active: false, data: schedule })
                              }

                              e.stopPropagation()
                            }}>
                              <IoIosMove style={{ height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                        </div>
                        <div className="choice-headers">
                          <div className="column">
                            <div className="choice-icon">
                              <CiClock1 style={{ color: 'black', height: '80%', width: '80%' }}/>
                            </div>
                          </div>
                          <div className="column"><div className="choice-header"><strong>TITLE:</strong> {schedule.name}</div></div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
            :
            <div id="empty-choice">
              <div id="empty-choice-header">
                {datas.length > 0 ? "Select your" : "Start with a"}<br/>
                FOLDER, PROJECT<br/>
                on the left to add your<br/>
                TODOLISTS, SCHEDULES AND NOTES
              </div>
            </div>
          }
        </div>
        <div id="notes-list">
          <div id="notes-list-header">ALL YOUR NOTES<br/>({allNotes.length})</div>

          {allNotesloading ? 
            <div id="loading-box" style={{ height: 20 }}>
              <div id="loading-container">
                <div id="loading-icon" style={{ height: 20, width: 20 }}>
                  <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                </div>
              </div>
            </div>
            :
            allNotes.map((note, index) => (
              <div className="note" key={note.key} onClick={(e) => {
                seeTheNote(note.choiceId)

                e.stopPropagation()
              }}>
                <div className="row">
                  <div className="note-actions">
                    <div className="note-action-icon" onClick={() => seeTheNote(note.choiceId)}>
                      <FiEdit2 style={{ height: '100%', width: '100%' }}/>
                    </div>
                    <div className="note-action-icon" onClick={(e) => {
                      if (!movingChoice.active) {
                        moveTheChoice(note)
                      } else {
                        setMovingchoice({ ...movingChoice, active: false, data: note })
                      }

                      e.stopPropagation()
                    }}>
                      <IoIosMove style={{ height: '100%', width: '100%' }}/>
                    </div>
                    <div className="note-action-icon" onClick={(e) => {
                      removeTheNote(note.choiceId, "Note", note.title)

                      e.stopPropagation()
                    }}>
                      <IoMdClose style={{ height: '100%', width: '100%' }}/>
                    </div>
                  </div>
                </div>

                <div className="note-header"><strong>TITLE:</strong> {note.title}</div>
                <div className="note-info-header">{note.info}.....</div>
                {note.from && <div className="note-mini-header"><strong>{note.from.type}:</strong> {note.from.name}</div>}
                <div className="note-mini-header"><strong>Created:</strong> {note.created}</div>
              </div>
            ))
          }
        </div>
      </div>

      {(
        report
        ||
        introBox.show
        ||
        paymentBox.show
        ||
        showInactiveplan
        ||
        addChoicebox.show || addFolder.show || addProject.show || addTodolist.show || addTodolistitem.show || postponeTaskbox.show || addNote.show
        ||
        deletableBox.show
        ||
        deleteChoicebox.show
        ||
        seeNotebox.show
        ||
        seeAllnotesbox.show
        ||
        usageRecords.show
        ||
        requestFeaturebox.show
        ||
        showLicenses
      ) && (
        <div id="hidden" onClick={(e) => {
          if (usageRecords.show) {
            if (usageRecords.planPaused) {
              setShowinactiveplan(true)
            }

            setUsagerecords({ ...usageRecords, show: false })
          } else {
            setReport(false)
            setPaymentbox({ ...paymentBox, show: false })
            setAddchoicebox({ ...addChoicebox, show: false, type: '' })
            cancelFolder()
            cancelProject()
            cancelTodolist()
            cancelTask()
            cancelPostponeTask()
            setAddnote({ ...addNote, show: false })
            setDeletablebox({ ...deletableBox, show: false, errorMsg: '' })
            setDeletechoicebox({ ...deleteChoicebox, show: false })
            setSeenotebox({ ...seeNotebox, show: false, id: -1, title: '', info: '' })
            setSeeallnotesbox({ ...seeAllnotesbox, show: false, list: [] })
            setRequestfeaturebox({ ...requestFeaturebox, show: false, loading: false })
            setShowlicenses(false)
          }
          
          e.stopPropagation()
        }}>
          {(introBox.show) && (
            <div id="intro-box" onClick={(e) => e.stopPropagation()}>
              {introBox.step === 0 && (
                <>
                  <div className="intro-header">
                    Welcome to DETACH
                  </div>

                  <div>
                    <div className="intro-mini-header">
                      We keep your work organized ALL-ON-ONE SCREEN<br/>
                      so you can (DETACH) from it and relax whenever you want
                    </div>

                    <div className="intro-image">
                      <img src={(process.env.NODE_ENV === "production" ? process.env.REACT_APP_ASSET + "/" : "/") + "beach.jpg"} alt="beach"/>
                    </div>
                  </div>
                </>
              )}

              {introBox.step === 1 && (
                <>
                  <div className="intro-header">
                    Create your<br/>
                  </div>

                  <div id="intro-icons">
                    <div className="row">
                      <div id="icons">
                        <div className="icon-holder">
                          <div className="icon-header">NOTES</div>
                          <div className="icon"><MdEventNote style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                        </div>
                        <div className="icon-holder">
                          <div className="icon-header">SCHEDULES</div>
                          <div className="icon"><CiClock1 style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                        </div>
                        <div className="icon-holder">
                          <div className="icon-header">TO-DO LISTS</div>
                          <div className="icon"><CiViewList style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                        </div>
                        <div className="icon-holder">
                          <div className="icon-header">PROJECTS</div>
                          <div className="icon"><AiFillProject style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="intro-header">
                    and manage them ALL-ON-ONE SCREEN<br/>
                    without a single<br/>HEADACHE or FRUSTRATION
                  </div>
                </>
              )}

              {introBox.step === 2 && (
                <>
                  <div className="intro-header">A quick demonstration presenting</div>

                  <div className="intro-mini-header">
                    The easiest way of<br/>creating a to-do list (34 seconds)
                  </div>

                  <div id="demo">
                    <video src="/demo.mp4" autoPlay style={{ height: '100%', width: '100%' }} controls/>
                  </div>
                </>
              )}

              <div>
                <div className="row">
                  <div id="dots">
                    <div className={"dot" + (introBox.step === 0 ? "-selected" : "")}/>
                    <div className={"dot" + (introBox.step === 1 ? "-selected" : "")}/>
                    <div className={"dot" + (introBox.step === 2 ? "-selected" : "")}/>
                  </div>
                </div>

                <div id="actions">
                  <div className="action" onClick={() => {
                    const newStep = introBox.step - 1

                    if (introBox.step > 0) {
                      setIntrobox({ ...introBox, step: newStep })
                    }
                  }}><GrFormPrevious style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                  <div className="action" onClick={() => {
                    const newStep = introBox.step + 1

                    if (introBox.step < 2) {
                      setIntrobox({ ...introBox, step: newStep })
                    }
                  }}><GrFormNext style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                </div>

                {introBox.step === 2 && <div id="intro-done" onClick={() => readedTheIntro()}>Start Using</div>}
              </div>
            </div>
          )}
          {(paymentBox.show) && (
            <div id="payment-box" onClick={(e) => e.stopPropagation()}>
              <div id="payment-header">
                <div className="row">
                  <div className="row">
                    <div className="star-icon"><FaStar style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                    <div className="star-icon"><FaRegStar style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                    <div className="star-icon"><FaStar style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                    <div className="star-icon"><FaRegStar style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                    <div className="star-icon"><FaStar style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                  </div>
                </div>
                <div id="payment-header">THE BEST PLAN</div>
                <div className="row">
                  <div className="row">
                    <div className="star-icon"><FaStar style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                    <div className="star-icon"><FaRegStar style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                    <div className="star-icon"><FaStar style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                    <div className="star-icon"><FaRegStar style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                    <div className="star-icon"><FaStar style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                  </div>
                </div>
              </div>

              <div id="payment-header">Use Detach to your fullest with all the features you need in this plan</div>

              <div className="row">
                <div id="payment-plan">
                  <div id="payment-plan-top">
                    <div id="payment-plan-header">STANDARD</div>
                    <hr/>
                    <div id="payment-plan-price-header">
                      ${pricePlan} CAD / Month
                      <div>(Start with a 7 days free trial)</div>
                    </div>
                    <ul>
                      <li>Up to 25 Tasks, 25 Schedules and 20 Todolists</li>
                      <li>Up to 50 Notes</li>
                      <li>Up to 5 Projects</li>
                    </ul>
                  </div>

                  {paymentBox.loading && (
                    <div id="loading-box" style={{ height: 20 }}>
                      <div id="loading-container">
                        <div id="loading-icon" style={{ height: 20, width: 20 }}>
                          <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className={"action" + (paymentBox.loading ? "-disabled" : "")} onClick={() => {
                      if (!paymentBox.loading) {
                        createTheCheckout()
                      }
                    }}>
                      <div className="action-header">Select plan</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(showInactiveplan) && (
            <div id="inactive-plan-box" onClick={(e) => e.stopPropagation()}>
              <div id="inactive-plan-logo">
                <img src="/logo.png" alt="logo"/>
              </div>

              <div id="inactive-plan-header">
                Your subscription is inactive. Please resume your plan to continue
                using Detach
              </div>

              <div id="inactive-plan-buttons">
                <div className="inactive-plan-button" onClick={() => {
                  setShowinactiveplan(false)
                  getTheUsageRecords()
                }}>Ok</div>
                <div className="inactive-plan-button" onClick={() => {
                  localStorage.clear()

                  navigate("/auth")
                }}>Log-Out</div>
              </div>
            </div>
          )}
          {(addChoicebox.show) && (
            <div id="addchoice-box" onClick={(e) => e.stopPropagation()}>
              <div id="add-headers">
                {(
                  (
                    !addChoicebox.show
                    &&
                    (
                      addProject.show || addTodolist.show || addTodolistitem.show
                    )
                  )
                ) && (
                  <div onClick={(e) => {
                    e.stopPropagation()

                    setAddnote({ ...addNote, show: false })
                    setAddchoicebox({ ...addChoicebox, show: true })
                  }}><FaArrowLeft /></div>
                )}

                <div id="add-header" style={{ width: (!addChoicebox.show && datas.length > 0) ? 'calc(100% - 30px)' : '100%' }}>
                  {addChoicebox.show && 'What do you want to create'}
                </div>
              </div>

              <div id="options" style={{ width: '100%' }}>
                <div className="option" onClick={(e) => {
                  isTheExceedUsage("Folder")

                  e.stopPropagation()
                }}>
                  <div className="row">
                    <div className="option-icon">
                      <FaRegFolderClosed style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                    <div className="column"><div className="option-header">A folder</div></div>
                  </div>
                </div>

                <div className="option" onClick={(e) => {
                  isTheExceedUsage("Project")

                  e.stopPropagation()
                }}>
                  <div className="row">
                    <div className="option-icon">
                      <AiFillProject style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                    <div className="column"><div className="option-header">A project</div></div>
                  </div>
                </div>
              </div>

              <div id="button-actions">
                <div className="button-action" onClick={() => setAddchoicebox({ ...addChoicebox, show: false })}>Cancel</div>
              </div>
            </div>
          )}
          {(addFolder.show) && (
            <div id="addfolder-box" onClick={(e) => e.stopPropagation()}>
              <div id="add-headers">
                {(!addChoicebox.show && datas.length > 0) && (
                  <div onClick={(e) => {
                    if (!addFolder.loading) {
                      e.stopPropagation()

                      setAddfolder({ ...addFolder, show: false })
                      setAddchoicebox({ ...addChoicebox, show: true })
                    }
                  }}><FaArrowLeft /></div>
                )}

                <div id="add-header" style={{ width: (!addChoicebox.show && datas.length > 0) ? 'calc(100% - 30px)' : '100%' }}>Add a folder</div>
              </div>

              <div className="inputs">
                <div className="input-container">
                  <div className="input-header">Folder name:</div>
                  <input
                    className="input-value" placeholder="Enter the folder name"
                    type="text" onChange={e => setAddfolder({ ...addFolder, name: e.target.value, errorMsg: "" })} value={addFolder.name}
                    maxLength="50" disabled={addFolder.loading}
                    onKeyDown={e => {
                      e.stopPropagation()

                      if (e.keyCode === 13) {
                        addTheFolder()
                      }
                    }}
                  />
                </div>
              </div>

              <div>
                <div id="errormsg">{addFolder.errorMsg}</div>

                {addFolder.loading && (
                  <div id="loading-box" style={{ height: 20 }}>
                    <div id="loading-container">
                      <div id="loading-icon" style={{ height: 20, width: 20 }}>
                        <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                    </div>
                  </div>
                )}

                <div id="button-actions">
                  <div className={"button-action" + (addFolder.loading ? "-disabled" : "")} onClick={addFolder.loading ? null : () => {
                    setAddfolder({ ...addFolder, show: false, errorMsg: '' })
                  }}>Cancel</div>

                  <div className={"button-action" + (addFolder.loading ? "-disabled" : "")} onClick={addFolder.loading ? null : (e) => {
                    addTheFolder()

                    e.stopPropagation()
                  }}>{
                    postponeTaskbox.show ? "Update" : "Add"
                  }</div>
                </div>
              </div>
            </div>
          )}
          {(addProject.show) && (
            <div id="addproject-box" onClick={(e) => e.stopPropagation()}>
              <div>
                <div id="add-headers">
                  {(!addChoicebox.show && datas.length > 0) && (
                    <div onClick={(e) => {
                      if (!addProject.loading) {
                        e.stopPropagation()

                        cancelProject()
                        setAddchoicebox({ ...addChoicebox, show: true })
                      }
                    }}><FaArrowLeft /></div>
                  )}

                  <div id="add-header" style={{ width: (!addChoicebox.show && datas.length > 0) ? 'calc(100% - 30px)' : '100%' }}>Add a project</div>
                </div>

                <div className="inputs">
                  <div className="input-container">
                    <div className="input-header">Project name:</div>
                    <input 
                      className="input-value" placeholder="Enter the project name" 
                      type="text" 
                      onChange={e => setAddproject({ ...addProject, name: e.target.value, errorMsg: "" })} value={addProject.name}
                      maxLength="50" disabled={addProject.loading} 
                      onKeyDown={e => {
                        e.stopPropagation()
        
                        if (e.keyCode === 13) {
                          addTheProject()
                        }
                      }}
                    />
                  </div>

                  {addProject.timeType && (
                    <div className="row">
                      <div id="cancel-set-hour" onClick={addProject.loading ? null : () => setAddproject({ ...addProject, timeType: '' })}>
                        <div className="column"><div id="cancel-set-hour-icon"><IoIosClose style={{ color: 'white', height: '100%', width: '100%' }}/></div></div>
                        <div className="column"><div id="cancel-set-hour-header">Don't Set DateTime</div></div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="set-hour-options">
                      {addProject.timeType !== "startenddatetime" && (
                        <div className={"set-hour-option" + (addProject.loading ? "-disabled" : "")} onClick={addProject.loading ? null : () => createCalendar("", "project", { timeType: "startenddatetime" }, 0)}>
                          <div className="column"><div className="set-hour-option-header">Set Start/End DateTime</div></div>
                          <div className="column"><div className="set-hour-option-icon"><TimeFrameIcon size={20}/></div></div>
                        </div>
                      )}
                      {addProject.timeType !== "duedatetime" && (
                        <div className={"set-hour-option" + (addProject.loading ? "-disabled" : "")} onClick={addProject.loading ? null : () => createCalendar("", "project", { timeType: "duedatetime" }, 0)}>
                          <div className="column"><div className="set-hour-option-header">Set Due DateTime</div></div>
                          <div className="column"><div className="set-hour-option-icon"><IoCalendarOutline style={{ color: 'black', height: '100%', width: '100%' }}/></div></div>
                        </div>
                      )}
                    </div>
                  </div>
                  
                  <div id="project-timecalendar">
                    {addProject.timeType && (
                      <>
                        {addProject.timeType === "startenddatetime" && (
                          <div className="datetime-selection">
                            <div className="datetime-selection-header">Select Start Date/Time</div>
                            <div className="date-selection">
                              <div className="date-selection-headers">
                                <div className="date-selection-arrow" onClick={() => createCalendar("prev", "project", "start", 0)}>
                                  <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                                </div>
                                <div className="date-selection-header">{addProject.start.calendar.header}</div>
                                <div className="date-selection-arrow" onClick={() => createCalendar("next", "project", "start", 0)}>
                                  <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                                </div>
                              </div>
                              <div className="date-selection-dates">
                                <div className="date-selection-date-header-row">
                                  {days.map((day, index) => <div key={"day-header-" + index} className="date-selection-date-header-row-data">{day}</div>)}
                                </div>

                                {addProject.start.calendar.dates.map((info, infoIndex) => (
                                  <div className="date-selection-date-row" key={"todolist-calendar-" + infoIndex}>
                                    {info.row.map((date, dateIndex) => (
                                      <div 
                                        key={"calendar-date-" + dateIndex} 
                                        className={
                                          "date-selection-date-row-data" + (
                                            addProject.start.calendar.selectedDate === date.date ? 
                                              "-selected" 
                                              : 
                                              (date.date === -1 || Date.now() >= date.unix) ? 
                                                "-disabled" 
                                                : 
                                                "")
                                        }
                                        onClick={() => {
                                          if (date.date > 0 && date.unix > Date.now()) {
                                            const newAddproject = {...addProject}

                                            newAddproject.start.calendar.selectedDate = date.date
                                            newAddproject.errorMsg = ""

                                            setAddproject(newAddproject)
                                          }
                                        }}
                                      >
                                        {date.date > -1 ? date.date : ''}
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                              <div className="time-selections">
                                <div className="time-selection">
                                  <div className="selection-arrow" onClick={() => adjustDueTime("hour", "up", "add", "project", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  <div className="selection-header">{addProject.start.time.hour}</div>
                                  <div className="selection-arrow" onClick={() => adjustDueTime("hour", "down", "add", "project", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                </div>
                                <div className="column"><div id="time-selection-div">:</div></div>
                                <div className="time-selection">
                                  <div className="selection-arrow" onClick={() => adjustDueTime("minute", "up", "add", "project", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  <div className="selection-header">{addProject.start.time.minute}</div>
                                  <div className="selection-arrow" onClick={() => adjustDueTime("minute", "down", "add", "project", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                </div>
                                <div className="time-selection">
                                  <div className="selection-arrow" onClick={() => adjustDueTime("period", "", "add", "project", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  <div className="selection-header">{addProject.start.time.period}</div>
                                  <div className="selection-arrow" onClick={() => adjustDueTime("period", "", "add", "project", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="datetime-selection">
                          <div className="datetime-selection-header">Select End Date/Time</div>
                          <div className="date-selection">
                            <div className="date-selection-headers">
                              <div className="date-selection-arrow" onClick={() => createCalendar("prev", "project", "end", 0)}>
                                <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                              </div>
                              <div className="date-selection-header">{addProject.end.calendar.header}</div>
                              <div className="date-selection-arrow" onClick={() => createCalendar("next", "project", "end", 0)}>
                                <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                              </div>
                            </div>
                            <div className="date-selection-dates">
                              <div className="date-selection-date-header-row">
                                {days.map((day, index) => <div className="date-selection-date-header-row-data">{day}</div>)}
                              </div>

                              {addProject.end.calendar.dates.map((info, infoIndex) => (
                                <div className="date-selection-date-row" key={"todolist-calendar-" + infoIndex}>
                                  {info.row.map((date, dateIndex) => (
                                    <div 
                                      key={"calendar-date-" + dateIndex} 
                                      className={"date-selection-date-row-data" + (addProject.end.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                      onClick={() => {
                                        if (date.date > 0 && date.unix > Date.now()) {
                                          const newAddproject = {...addProject}

                                          newAddproject.end.calendar.selectedDate = date.date

                                          setAddproject(newAddproject)
                                        }
                                      }}
                                    >
                                      {date.date > -1 ? date.date : ''}
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                            <div className="time-selections">
                              <div className="time-selection">
                                <div className="selection-arrow" onClick={() => adjustDueTime("hour", "up", "add", "project", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                <div className="selection-header">{addProject.end.time.hour}</div>
                                <div className="selection-arrow" onClick={() => adjustDueTime("hour", "down", "add", "project", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                              </div>
                              <div className="column"><div id="time-selection-div">:</div></div>
                              <div className="time-selection">
                                <div className="selection-arrow" onClick={() => adjustDueTime("minute", "up", "add", "project", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                <div className="selection-header">{addProject.end.time.minute}</div>
                                <div className="selection-arrow" onClick={() => adjustDueTime("minute", "down", "add", "project", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                              </div>
                              <div className="time-selection">
                                <div className="selection-arrow" onClick={() => adjustDueTime("period", "", "add", "project", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                <div className="selection-header">{addProject.end.time.period}</div>
                                <div className="selection-arrow" onClick={() => adjustDueTime("period", "", "add", "project", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div id="errormsg">{addProject.errorMsg}</div>

                {addProject.loading && (
                  <div id="loading-box" style={{ height: 20 }}>
                    <div id="loading-container">
                      <div id="loading-icon" style={{ height: 20, width: 20 }}>
                        <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                    </div>
                  </div>
                )}
                <div id="button-actions">
                  <div className={"button-action" + (addProject.loading ? "-disabled" : "")} onClick={addProject.loading ? null : () => {
                    cancelProject()
                  }}>Cancel</div>

                  <div className={"button-action" + (addProject.loading ? "-disabled" : "")} onClick={addProject.loading ? null : (e) => {
                    addTheProject()

                    e.stopPropagation()
                  }}>{
                    postponeTaskbox.show ? "Update" : "Add"
                  }</div>
                </div>
              </div>
            </div>
          )}
          {(addTodolist.show) && (
            <div id="addnewtodolist-box" onClick={(e) => e.stopPropagation()}>
              <div id="add-headers">
                <div id="add-header" style={{ width: '100%' }}>Add a new to-do list</div>
              </div>

              <div className="inputs">
                <div className="input-container">
                  <div className="input-header">What's the name of this todo list:</div>
                  <input 
                    className="input-value" placeholder="Enter the name of this todo list" 
                    type="text" 
                    onChange={addTodolist.loading ? null : (e) => setAddtodolist({ ...addTodolist, name: e.target.value, errorMsg: "" })} 
                    value={addTodolist.name} maxLength="50" disabled={addTodolist.loading} 
                    onKeyDown={e => {
                      e.stopPropagation()
      
                      if (e.keyCode === 13) {
                        addTheTodolist()
                      }
                    }}
                  />
                </div>

                <div id="todolist-list">
                  {addTodolist.list.map((listItem, index) => (
                    <div className="list-item-container">
                      <div className="list-item-header">Adding task #{index + 1}</div>
                      <div className="list-item" key={"todolist-" + index}>
                        <div className="column" style={{ width: '30%' }}>
                          <div className="list-item-headers">
                            <div className="list-item-delete" onClick={() => {
                              const newList = [...addTodolist.list]

                              newList.splice(index, 1)

                              setAddtodolist({ ...addTodolist, list: newList })
                            }}>
                              <IoIosClose style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>

                            <div className="list-item-input">
                              <div className="list-item-input-header">Enter task name</div>
                              <input  
                                value={listItem.header}
                                onChange={(e) => {
                                  const newList = [...addTodolist.list]

                                  newList[index].header = e.target.value

                                  setAddtodolist({ ...addTodolist, list: newList })
                                }}
                                onKeyUp={(e) => {
                                  if (e.keyCode === 13) {
                                    const newList = [...addTodolist.list]

                                    newList[index].editing = false

                                    setAddtodolist({ ...addTodolist, list: newList })
                                  }
                                }}
                                maxLength="50"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="column" style={{ width: '70%' }}>
                          <div className="todolist-list-calendar-option-container">
                            {listItem.timeType && (
                              <div className="row" style={{ marginBottom: 20 }}>
                                <div id="cancel-set-hour" onClick={addTodolist.loading ? null : () => {
                                  const newList = [...addTodolist.list]

                                  newList[index].timeType = ''

                                  setAddtodolist({ ...addTodolist, list: newList })
                                }}>
                                  <div id="cancel-set-hour-icon"><IoIosClose style={{ color: 'white', height: '100%', width: '100%' }}/></div>
                                  <div id="cancel-set-hour-header">Don't Set DateTime</div>
                                </div>
                              </div>
                            )}

                            <div className="row">
                              <div className="set-hour-options">
                                {listItem.timeType !== "startenddatetime" && (
                                  <div className={"set-hour-option" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => {
                                    const newAddtodolist = {...addTodolist}
                                    const { timeType } = newAddtodolist.list[index]

                                    createCalendar("", "todolist", { timeType: "startenddatetime", index }, 0)
                                  }}>
                                    <div className="column"><div className="set-hour-option-header">Set Start/End DateTime</div></div>
                                    <div className="column"><div className="set-hour-option-icon"><TimeFrameIcon size={20}/></div></div>
                                  </div>
                                )}
                                {listItem.timeType !== "duedatetime" && (
                                  <div className={"set-hour-option" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => {
                                    const newAddtodolist = {...addTodolist}
                                    const { timeType } = newAddtodolist.list[index]

                                    createCalendar("", "todolist", { timeType: "duedatetime", index }, 0)
                                  }}>
                                    <div className="column"><div className="set-hour-option-header">Set Due DateTime</div></div>
                                    <div className="column"><div className="set-hour-option-icon"><IoCalendarOutline style={{ color: 'black', height: '100%', width: '100%' }}/></div></div>
                                  </div>
                                )}
                              </div>
                            </div>

                            {listItem.timeType && (
                              <div className="todolist-list-timecalendar" onClick={(e) => e.stopPropagation()}>
                                {listItem.timeType === "startenddatetime" && (
                                  <div className="datetime-selection">
                                    <div className="datetime-selection-header">Select Start DateTime</div>
                                    <div className="date-selection">
                                      <div className="date-selection-headers">
                                        <div className={"date-selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => createCalendar("prev", "todolist", { index, type: "start" }, 0)}>
                                          <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                                        </div>
                                        <div className="date-selection-header">{listItem.start.calendar.header}</div>
                                        <div className={"date-selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => createCalendar("next", "todolist", { index, type: "start" }, 0)}>
                                          <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                                        </div>
                                      </div>
                                      <div className="date-selection-dates">
                                        <div className="date-selection-date-header-row">
                                          <div className="date-selection-date-header-row-data">Sun</div>
                                          <div className="date-selection-date-header-row-data">Mon</div>
                                          <div className="date-selection-date-header-row-data">Tue</div>
                                          <div className="date-selection-date-header-row-data">Wed</div>
                                          <div className="date-selection-date-header-row-data">Thu</div>
                                          <div className="date-selection-date-header-row-data">Fri</div>
                                          <div className="date-selection-date-header-row-data">Sat</div>
                                        </div>

                                        {listItem.start.calendar.dates.map((info, infoIndex) => (
                                          <div className="date-selection-date-row" key={"todolist-calendar-" + infoIndex}>
                                            {info.row.map((date, dateIndex) => (
                                              <div 
                                                key={"calendar-date-" + dateIndex} 
                                                className={"date-selection-date-row-data" + (addTodolist.loading ? "-disabled" : listItem.start.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                                onClick={addTodolist.loading ? null : () => {
                                                  if (date.date > 0 && date.unix > Date.now()) {
                                                    const newAddtodolist = {...addTodolist}

                                                    newAddtodolist.list[index].start.calendar.selectedDate = date.date
                                                    newAddtodolist.errorMsg = ""

                                                    setAddtodolist(newAddtodolist)
                                                  }
                                                }}
                                              >
                                                {date.date > -1 ? date.date : ''}
                                              </div>
                                            ))}
                                          </div>
                                        ))}
                                      </div>
                                      <div className="time-selections">
                                        <div className="time-selection">
                                          <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("hour", "up", "add", "todolist", { index, type: "start" })}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                          <div className="selection-header">{listItem.start.time.hour}</div>
                                          <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("hour", "down", "add", "todolist", { index, type: "start" })}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                        </div>
                                        <div className="column"><div id="time-selection-div">:</div></div>
                                        <div className="time-selection">
                                          <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("minute", "up", "add", "todolist", { index, type: "start" })}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                          <div className="selection-header">{listItem.start.time.minute}</div>
                                          <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("minute", "down", "add", "todolist", { index, type: "start" })}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                        </div>
                                        <div className="time-selection">
                                          <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("period", "", "add", "todolist", { index, type: "start" })}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                          <div className="selection-header">{listItem.start.time.period}</div>
                                          <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("period", "", "add", "todolist", { index, type: "start" })}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div className="datetime-selection">
                                  <div className="datetime-selection-header">Select End DateTime</div>
                                  <div className="date-selection">
                                    <div className="date-selection-headers">
                                      <div className={"date-selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => createCalendar("prev", "todolist", { index, type: "end" }, 0)}>
                                        <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                                      </div>
                                      <div className="date-selection-header">{listItem.end.calendar.header}</div>
                                      <div className={"date-selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => createCalendar("next", "todolist", { index, type: "end" }, 0)}>
                                        <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                                      </div>
                                    </div>
                                    <div className="date-selection-dates">
                                      <div className="date-selection-date-header-row">
                                        <div className="date-selection-date-header-row-data">Sun</div>
                                        <div className="date-selection-date-header-row-data">Mon</div>
                                        <div className="date-selection-date-header-row-data">Tue</div>
                                        <div className="date-selection-date-header-row-data">Wed</div>
                                        <div className="date-selection-date-header-row-data">Thu</div>
                                        <div className="date-selection-date-header-row-data">Fri</div>
                                        <div className="date-selection-date-header-row-data">Sat</div>
                                      </div>

                                      {listItem.end.calendar.dates.map((info, infoIndex) => (
                                        <div className="date-selection-date-row" key={"todolist-calendar-" + infoIndex}>
                                          {info.row.map((date, dateIndex) => (
                                            <div 
                                              key={"calendar-date-" + dateIndex} 
                                              className={"date-selection-date-row-data" + (addTodolist.loading ? "-disabled" : listItem.end.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                              onClick={addTodolist.loading ? null : () => {
                                                if (date.date > 0 && date.unix > Date.now()) {
                                                  const newAddtodolist = {...addTodolist}

                                                  newAddtodolist.list[index].end.calendar.selectedDate = date.date
                                                  newAddtodolist.errorMsg = ""

                                                  setAddtodolist(newAddtodolist)
                                                }
                                              }}
                                            >
                                              {date.date > -1 ? date.date : ''}
                                            </div>
                                          ))}
                                        </div>
                                      ))}
                                    </div>
                                    <div className="time-selections">
                                      <div className="time-selection">
                                        <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("hour", "up", "add", "todolist", { index, type: "end" })}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                        <div className="selection-header">{listItem.end.time.hour}</div>
                                        <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("hour", "down", "add", "todolist", { index, type: "end" })}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                      </div>
                                      <div className="column"><div id="time-selection-div">:</div></div>
                                      <div className="time-selection">
                                        <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("minute", "up", "add", "todolist", { index, type: "end" })}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                        <div className="selection-header">{listItem.end.time.minute}</div>
                                        <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("minute", "down", "add", "todolist", { index, type: "end" })}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                      </div>
                                      <div className="time-selection">
                                        <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("period", "", "add", "todolist", { index, type: "end" })}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                        <div className="selection-header">{listItem.end.time.period}</div>
                                        <div className={"selection-arrow" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => adjustDueTime("period", "", "add", "todolist", { index, type: "end" })}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div id="todolist-actions">
                <div className={"todolist-action" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => {
                  const newList = [...addTodolist.list]
                  let empty = false

                  newList.forEach(function (info) {
                    if (!info.header) {
                      empty = true
                    }
                  })

                  if (empty) {
                    setAddtodolist({ ...addTodolist, list: newList, errorMsg: "There is an empty task" })
                  } else {
                    newList.push({
                      key: "todolist-" + newList.length,
                      header: "",
                      timeType: '',
                      start: {
                        scheduled: null,
                        calendar: {
                          header: "",
                          dates: [
                            {
                              key: "calendar-row-0",
                              row: [{}, {}, {}, {}, {}, {}, {}]
                            },
                            {
                              key: "calendar-row-1",
                              row: [{}, {}, {}, {}, {}, {}, {}]
                            },
                            {
                              key: "calendar-row-2",
                              row: [{}, {}, {}, {}, {}, {}, {}]
                            },
                            {
                              key: "calendar-row-3",
                              row: [{}, {}, {}, {}, {}, {}, {}]
                            },
                            {
                              key: "calendar-row-4",
                              row: [{}, {}, {}, {}, {}, {}, {}]
                            }
                          ],
                          selectedDate: 0,
                          monthNum: 0,
                          yearNum: 0
                        },
                        time: {
                          hour: 0,
                          minute: 0,
                          period: "am"
                        }
                      },
                      end: {
                        scheduled: null,
                        calendar: {
                          header: "",
                          dates: [
                            {
                              key: "calendar-row-0",
                              row: [{}, {}, {}, {}, {}, {}, {}]
                            },
                            {
                              key: "calendar-row-1",
                              row: [{}, {}, {}, {}, {}, {}, {}]
                            },
                            {
                              key: "calendar-row-2",
                              row: [{}, {}, {}, {}, {}, {}, {}]
                            },
                            {
                              key: "calendar-row-3",
                              row: [{}, {}, {}, {}, {}, {}, {}]
                            },
                            {
                              key: "calendar-row-4",
                              row: [{}, {}, {}, {}, {}, {}, {}]
                            }
                          ],
                          selectedDate: 0,
                          monthNum: 0,
                          yearNum: 0
                        },
                        time: {
                          hour: 0,
                          minute: 0,
                          period: "am"
                        }
                      }
                    })

                    setAddtodolist({ ...addTodolist, list: newList })
                  }
                }}>
                  <div className="todolist-action-header">Add a task</div>
                  <div className="column">
                    <div className="todolist-action-icon">
                      <MdWorkOutline style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                  </div>
                </div>
              </div>

              <div id="errormsg">{addTodolist.errorMsg}</div>

              {addTodolist.loading && (
                <div id="loading-box" style={{ height: 20 }}>
                  <div id="loading-container">
                    <div id="loading-icon" style={{ height: 20, width: 20 }}>
                      <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                  </div>
                </div>
              )}

              <div id="button-actions">
                <div className={"button-action" + (addTodolist.loading ? "-disabled" : "")} onClick={addTodolist.loading ? null : () => addTheTodolist()}>Add Todolist</div>
              </div>
            </div>
          )}
          {(addTodolistitem.show) && (
            <div id="addnewtask-box" onClick={(e) => e.stopPropagation()}>
              {addTodolistitem.type == '' ? 
                <>
                  <div id="addnewtask-header">Create just a task or schedule ?</div>
                  <div className="row">
                    <div id="addnewtask-options">
                      <div className="addnewtask-option" onClick={() => setAddtodolistitem({ ...addTodolistitem, type: 'Schedule' })}>Schedule</div>
                      <div className="addnewtask-option" onClick={() => setAddtodolistitem({ ...addTodolistitem, type: 'Task' })}>Task</div>
                    </div>
                  </div>
                </>
                :
                <>
                  <div className="inputs">
                    {addTodolistitem.isTodolist && (
                      <div id="addnewtask-back" onClick={() => setAddtodolistitem({ ...addTodolistitem, type: '' })}>
                        <div id="addnewtask-back-icon">
                          <IoArrowBackSharp style={{ height: '100%', width: '100%' }}/>
                        </div>
                        <div className="column"><div id="addnewtask-back-header">BACK</div></div>
                      </div>
                    )}
                    
                    <div className="input-container">
                      <div className="input-header">What's the name of this new {addTodolistitem.type.toLowerCase()}:</div>
                      <input 
                        className="input-value" placeholder={"Enter the name of this new " + addTodolistitem.type.toLowerCase()}
                        type="text" onChange={e => setAddtodolistitem({ ...addTodolistitem, name: e.target.value, errorMsg: "" })} value={addTodolistitem.name}
                        maxLength="50" disabled={addTodolistitem.loading} 
                        onKeyDown={e => {
                          e.stopPropagation()
          
                          if (e.keyCode === 13) {
                            addTheTodolistItem()
                          }
                        }}
                      />
                    </div>

                    {addTodolistitem.timeType && (
                      <div className="row">
                        <div id="cancel-set-hour" onClick={addTodolistitem.loading ? null : () => setAddtodolistitem({ ...addTodolistitem, timeType: '' })}>
                          <div className="column"><div id="cancel-set-hour-icon"><IoIosClose style={{ color: 'white', height: '100%', width: '100%' }}/></div></div>
                          <div className="column"><div id="cancel-set-hour-header">Don't Set DateTime</div></div>
                        </div>
                      </div>
                    )}

                    {addTodolistitem.type == "Schedule" && (
                      <div className="row">
                        <div className="set-hour-options" style={{ margin: addTodolistitem.timeType !== '' ? '10px auto 20px auto' : '0 auto 50px auto'}}>
                          {addTodolistitem.timeType !== "startenddatetime" && (
                            <div className={"set-hour-option" + (addTodolistitem.loading ? "-disabled" : "")} onClick={addTodolistitem.loading ? null : () => {                        
                              createCalendar("", "task", { ...addTodolistitem.data, timeType: "startenddatetime" }, 0)
                            }}>
                              <div className="column"><div className="set-hour-option-header">Set Start/End DateTime</div></div>
                              <div className="column"><div className="set-hour-option-icon"><TimeFrameIcon size={20}/></div></div>
                            </div>
                          )}
                          {addTodolistitem.timeType !== "duedatetime" && (
                            <div className={"set-hour-option" + (addTodolistitem.loading ? "-disabled" : "")} onClick={addTodolistitem.loading ? null : () => {
                              createCalendar("", "task", { ...addTodolistitem.data, timeType: "duedatetime" }, 0)
                            }}>
                              <div className="column"><div className="set-hour-option-header">Set Due DateTime</div></div>
                              <div className="column"><div className="set-hour-option-icon"><IoCalendarOutline style={{ color: 'black', height: '100%', width: '100%' }}/></div></div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    {addTodolistitem.timeType && (
                      <div id="addnewtask-timecalendar">
                        {addTodolistitem.timeType === "startenddatetime" && (
                          <div className="addnewtask-calendar">
                            <div className="datetime-selection">
                              <div className="datetime-selection-header">SELECT START DATE/TIME</div>
                              <div className="date-selection">
                                <div className="date-selection-headers">
                                  <div className="date-selection-arrow" onClick={addTodolistitem.loading ? null : () => createCalendar("prev", "task", "start", 0)}>
                                    <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                  <div id="date-selection-header">{addTodolistitem.start.calendar.header}</div>
                                  <div className="date-selection-arrow" onClick={addTodolistitem.loading ? null : () => createCalendar("next", "task", "start", 0)}>
                                    <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                                  </div>
                                </div>
                                <div className="date-selection-dates">
                                  <div className="date-selection-date-header-row">
                                    <div className="date-selection-date-header-row-data">Sun</div>
                                    <div className="date-selection-date-header-row-data">Mon</div>
                                    <div className="date-selection-date-header-row-data">Tue</div>
                                    <div className="date-selection-date-header-row-data">Wed</div>
                                    <div className="date-selection-date-header-row-data">Thu</div>
                                    <div className="date-selection-date-header-row-data">Fri</div>
                                    <div className="date-selection-date-header-row-data">Sat</div>
                                  </div>

                                  {addTodolistitem.start.calendar.dates.map((info, index) => (
                                    <div className="date-selection-date-row" key={"newtask-" + index}>
                                      {info.row.map((date, index) => (
                                        <div 
                                          key={"calendar-date-" + index} 
                                          className={"date-selection-date-row-data" + (addTodolistitem.start.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                          onClick={addTodolistitem.loading ? null : () => {
                                            if (date.date > 0 && date.unix > Date.now()) {
                                              const newAddtask = {...addTodolistitem}

                                              newAddtask.start.calendar.selectedDate = date.date
                                              newAddtask.errorMsg = ""

                                              setAddtodolistitem(newAddtask)
                                            }
                                          }}
                                        >
                                          {date.date > -1 ? date.date : ''}
                                        </div>
                                      ))}
                                    </div>
                                  ))}
                                </div>
                                <div className="time-selections">
                                  <div className="time-selection">
                                    <div className="selection-arrow" onClick={addTodolistitem.loading ? null : () => adjustDueTime("hour", "up", "add", "task", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    <div className="selection-header">{addTodolistitem.start.time.hour}</div>
                                    <div className="selection-arrow" onClick={addTodolistitem.loading ? null : () => adjustDueTime("hour", "down", "add", "task", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  </div>
                                  <div className="column"><div id="time-selection-div">:</div></div>
                                  <div className="time-selection">
                                    <div className="selection-arrow" onClick={addTodolistitem.loading ? null : () => adjustDueTime("minute", "up", "add", "task", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    <div className="selection-header">{addTodolistitem.start.time.minute}</div>
                                    <div className="selection-arrow" onClick={addTodolistitem.loading ? null : () => adjustDueTime("minute", "down", "add", "task", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  </div>
                                  <div className="time-selection">
                                    <div className="selection-arrow" onClick={addTodolistitem.loading ? null : () => adjustDueTime("period", "", "add", "task", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                    <div className="selection-header">{addTodolistitem.start.time.period}</div>
                                    <div className="selection-arrow" onClick={addTodolistitem.loading ? null : () => adjustDueTime("period", "", "add", "task", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        
                        <div className="addnewtask-calendar">
                          <div className="datetime-selection">
                            <div className="datetime-selection-header">
                              SELECT END {addTodolistitem.timeType === "startenddatetime" ? "DATE/TIME" : "DUE DATE/TIME"}
                            </div>
                            <div className="date-selection">
                              <div className="date-selection-headers">
                                <div className="date-selection-arrow" onClick={() => createCalendar("prev", "task", "end", 0)}>
                                  <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                                </div>
                                <div id="date-selection-header">{addTodolistitem.end.calendar.header}</div>
                                <div className="date-selection-arrow" onClick={() => createCalendar("next", "task", "end", 0)}>
                                  <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                                </div>
                              </div>
                              <div className="date-selection-dates">
                                <div className="date-selection-date-header-row">
                                  <div className="date-selection-date-header-row-data">Sun</div>
                                  <div className="date-selection-date-header-row-data">Mon</div>
                                  <div className="date-selection-date-header-row-data">Tue</div>
                                  <div className="date-selection-date-header-row-data">Wed</div>
                                  <div className="date-selection-date-header-row-data">Thu</div>
                                  <div className="date-selection-date-header-row-data">Fri</div>
                                  <div className="date-selection-date-header-row-data">Sat</div>
                                </div>

                                {addTodolistitem.end.calendar.dates.map((info, index) => (
                                  <div className="date-selection-date-row" key={"newtask-" + index}>
                                    {info.row.map((date, index) => (
                                      <div 
                                        key={"calendar-date-" + index} 
                                        className={"date-selection-date-row-data" + (addTodolistitem.end.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                        onClick={() => {
                                          if (date.date > 0 && date.unix > Date.now()) {
                                            const newAddtask = {...addTodolistitem}

                                            newAddtask.end.calendar.selectedDate = date.date
                                            newAddtask.errorMsg = ""

                                            setAddtodolistitem(newAddtask)
                                          }
                                        }}
                                      >
                                        {date.date > -1 ? date.date : ''}
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                              <div className="time-selections">
                                <div className="time-selection">
                                  <div className="selection-arrow" onClick={() => adjustDueTime("hour", "up", "add", "task", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  <div className="selection-header">{addTodolistitem.end.time.hour}</div>
                                  <div className="selection-arrow" onClick={() => adjustDueTime("hour", "down", "add", "task", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                </div>
                                <div className="column"><div id="time-selection-div">:</div></div>
                                <div className="time-selection">
                                  <div className="selection-arrow" onClick={() => adjustDueTime("minute", "up", "add", "task", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  <div className="selection-header">{addTodolistitem.end.time.minute}</div>
                                  <div className="selection-arrow" onClick={() => adjustDueTime("minute", "down", "add", "task", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                </div>
                                <div className="time-selection">
                                  <div className="selection-arrow" onClick={() => adjustDueTime("period", "", "add", "task", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                  <div className="selection-header">{addTodolistitem.end.time.period}</div>
                                  <div className="selection-arrow" onClick={() => adjustDueTime("period", "", "add", "task", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <div id="errormsg" style={{ fontSize: 15 }}>{addTodolistitem.errorMsg}</div>

                    {addTodolistitem.loading && (
                      <div id="loading-box" style={{ height: 20 }}>
                        <div id="loading-container">
                          <div id="loading-icon" style={{ height: 20, width: 20 }}>
                            <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                          </div>
                        </div>
                      </div>
                    )}
                  
                    <div id="button-actions">
                      <div className={"button-action" + (addTodolistitem.loading ? "-disabled" : "")} onClick={addTodolistitem.loading ? null : (e) => {
                        cancelTask()

                        e.stopPropagation()
                      }}>Cancel</div>

                      <div className={"button-action" + (addTodolistitem.loading ? "-disabled" : "")} onClick={addTodolistitem.loading ? null : (e) => {
                        addTheTodolistItem()

                        e.stopPropagation()
                      }}>Add</div>
                    </div>
                  </div>
                </>
              }
            </div>
          )}
          {(postponeTaskbox.show) && (
            <div id="postponetask-box" onClick={(e) => e.stopPropagation()}>
              <div className="inputs">
                <div className="input-container">
                  <div className="input-header">What's the task call:</div>
                  <input 
                    className="input-value" placeholder="Enter the worker's task" 
                    type="text" onChange={e => setPostponetaskbox({ ...postponeTaskbox, name: e.target.value, errorMsg: "" })} value={postponeTaskbox.name}
                    maxLength="50" disabled={postponeTaskbox.loading} 
                    onKeyDown={e => {
                      e.stopPropagation()
      
                      if (e.keyCode === 13) {
                        postponeTheTask()
                      }
                    }}
                  />
                </div>

                {postponeTaskbox.timeType && (
                  <div className="row">
                    <div id="cancel-set-hour" onClick={postponeTaskbox.loading ? null : () => setPostponetaskbox({ ...postponeTaskbox, timeType: '' })}>
                      <div className="column"><div id="cancel-set-hour-icon"><IoIosClose style={{ color: 'white', height: '100%', width: '100%' }}/></div></div>
                      <div className="column"><div id="cancel-set-hour-header">Don't Set DateTime</div></div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="set-hour-options" style={{ margin: postponeTaskbox.timeType !== '' ? '10px auto 20px auto' : '0 auto 50px auto'}}>
                    {postponeTaskbox.timeType !== "startenddatetime" && (
                      <div className={"set-hour-option" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => {   
                        const { id, name } = {...postponeTaskbox}
                        const data = { id, name }

                        createCalendar("", "postpone", { ...data, timeType: "startenddatetime" }, 0)
                      }}>
                        <div className="column"><div className="set-hour-option-header">Set Start/End DateTime</div></div>
                        <div className="column"><div className="set-hour-option-icon"><TimeFrameIcon size={20}/></div></div>
                      </div>
                    )}
                    {postponeTaskbox.timeType !== "duedatetime" && (
                      <div className={"set-hour-option" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => {
                        const { id, name } = {...postponeTaskbox}
                        const data = { id, name }

                        createCalendar("", "postpone", { ...data, timeType: "duedatetime" }, 0)
                      }}>
                        <div className="column"><div className="set-hour-option-header">Set Due DateTime</div></div>
                        <div className="column"><div className="set-hour-option-icon"><IoCalendarOutline style={{ color: 'black', height: '100%', width: '100%' }}/></div></div>
                      </div>
                    )}
                  </div>
                </div>

                {postponeTaskbox.timeType && (
                  <div id="postponetask-timecalendar">
                    {postponeTaskbox.timeType === "startenddatetime" && (
                      <div className="postponetask-calendar">
                        <div className="datetime-selection">
                          <div className="datetime-selection-header">CHOOSE START DATE/TIME</div>
                          <div className="date-selection">
                            <div className="date-selection-headers">
                              <div className={"date-selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => createCalendar("prev", "postpone", "start", 0)}>
                                <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                              </div>
                              <div id="date-selection-header">{postponeTaskbox.start.calendar.header}</div>
                              <div className={"date-selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => createCalendar("next", "postpone", "start", 0)}>
                                <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                              </div>
                            </div>
                            <div className="date-selection-dates">
                              <div className="date-selection-date-header-row">
                                <div className="date-selection-date-header-row-data">Sun</div>
                                <div className="date-selection-date-header-row-data">Mon</div>
                                <div className="date-selection-date-header-row-data">Tue</div>
                                <div className="date-selection-date-header-row-data">Wed</div>
                                <div className="date-selection-date-header-row-data">Thu</div>
                                <div className="date-selection-date-header-row-data">Fri</div>
                                <div className="date-selection-date-header-row-data">Sat</div>
                              </div>

                              {postponeTaskbox.start.calendar.dates.map((info, index) => (
                                <div className="date-selection-date-row" key={"postponetask-" + index}>
                                  {info.row.map((date, index) => (
                                    <div 
                                      key={"calendar-date-" + index} 
                                      className={"date-selection-date-row-data" + (postponeTaskbox.loading ? "-disabled" : postponeTaskbox.start.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                      onClick={postponeTaskbox.loading ? null : () => {
                                        if (date.date > 0 && date.unix > Date.now()) {
                                          const newPostponetaskbox = {...postponeTaskbox}

                                          newPostponetaskbox.start.calendar.selectedDate = date.date
                                          newPostponetaskbox.errorMsg = ""

                                          setPostponetaskbox(newPostponetaskbox)
                                        }
                                      }}
                                    >
                                      {date.date > -1 ? date.date : ''}
                                    </div>
                                  ))}
                                </div>
                              ))}
                            </div>
                            <div className="time-selections">
                              <div className="time-selection">
                                <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("hour", "up", "update", "postpone", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                <div className="selection-header">{postponeTaskbox.start.time.hour}</div>
                                <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("hour", "down", "update", "postpone", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                              </div>
                              <div className="column"><div id="time-selection-div">:</div></div>
                              <div className="time-selection">
                                <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("minute", "up", "update", "postpone", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                <div className="selection-header">{postponeTaskbox.start.time.minute}</div>
                                <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("minute", "down", "update", "postpone", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                              </div>
                              <div className="time-selection">
                                <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("period", "", "update", "postpone", "start")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                                <div className="selection-header">{postponeTaskbox.start.time.period}</div>
                                <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("period", "", "update", "postpone", "start")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="postponetask-calendar">
                      <div className="datetime-selection">
                        <div className="datetime-selection-header">CHOOSE END {postponeTaskbox.timeType === "startenddatetime" ? "DATE/TIME" : "DUE DATE/TIME"}</div>
                        <div className="date-selection">
                          <div className="date-selection-headers">
                            <div className={"date-selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => createCalendar("prev", "postpone", "end", 0)}>
                              <FaArrowLeft style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                            <div id="date-selection-header">{postponeTaskbox.end.calendar.header}</div>
                            <div className={"date-selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => createCalendar("next", "postpone", "end", 0)}>
                              <FaArrowRight style={{ color: 'black', height: '100%', width: '100%' }}/>
                            </div>
                          </div>
                          <div className="date-selection-dates">
                            <div className="date-selection-date-header-row">
                              <div className="date-selection-date-header-row-data">Sun</div>
                              <div className="date-selection-date-header-row-data">Mon</div>
                              <div className="date-selection-date-header-row-data">Tue</div>
                              <div className="date-selection-date-header-row-data">Wed</div>
                              <div className="date-selection-date-header-row-data">Thu</div>
                              <div className="date-selection-date-header-row-data">Fri</div>
                              <div className="date-selection-date-header-row-data">Sat</div>
                            </div>

                            {postponeTaskbox.end.calendar.dates.map((info, index) => (
                              <div className="date-selection-date-row" key={"postponetask-" + index}>
                                {info.row.map((date, index) => (
                                  <div 
                                    key={"calendar-date-" + index} 
                                    className={"date-selection-date-row-data" + (postponeTaskbox.loading ? "-disabled" : postponeTaskbox.end.calendar.selectedDate === date.date ? "-selected" : (date.date === -1 || Date.now() >= date.unix) ? "-disabled" : "")}
                                    onClick={postponeTaskbox.loading ? null : () => {
                                      if (date.date > 0 && date.unix > Date.now()) {
                                        const newPostponetaskbox = {...postponeTaskbox}

                                        newPostponetaskbox.end.calendar.selectedDate = date.date
                                        newPostponetaskbox.errorMsg = ""

                                        setPostponetaskbox(newPostponetaskbox)
                                      }
                                    }}
                                  >
                                    {date.date > -1 ? date.date : ''}
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                          <div className="time-selections">
                            <div className="time-selection">
                              <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("hour", "up", "update", "postpone", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                              <div className="selection-header">{postponeTaskbox.end.time.hour}</div>
                              <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("hour", "down", "update", "postpone", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                            </div>
                            <div className="column"><div id="time-selection-div">:</div></div>
                            <div className="time-selection">
                              <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("minute", "up", "update", "postpone", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                              <div className="selection-header">{postponeTaskbox.end.time.minute}</div>
                              <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("minute", "down", "update", "postpone", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                            </div>
                            <div className="time-selection">
                              <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("period", "", "update", "postpone", "end")}><IoIosArrowUp style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                              <div className="selection-header">{postponeTaskbox.end.time.period}</div>
                              <div className={"selection-arrow" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : () => adjustDueTime("period", "", "update", "postpone", "end")}><IoIosArrowDown style={{ color: 'black', height: '100%', width: '100%' }}/></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                <div id="errormsg" style={{ fontSize: 15 }}>{postponeTaskbox.errorMsg}</div>

                {postponeTaskbox.loading && (
                  <div id="loading-box" style={{ height: 20 }}>
                    <div id="loading-container">
                      <div id="loading-icon" style={{ height: 20, width: 20 }}>
                        <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                    </div>
                  </div>
                )}

                <div id="button-actions">
                  <div className={"button-action" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : (e) => {
                    cancelPostponeTask()

                    e.stopPropagation()
                  }}>Cancel</div>

                  <div className={"button-action" + (postponeTaskbox.loading ? "-disabled" : "")} onClick={postponeTaskbox.loading ? null : (e) => {
                    postponeTheTask()

                    e.stopPropagation()
                  }}>{postponeTaskbox.show ? "Update" : "Add"}</div>
                </div>
              </div>
            </div>
          )}
          {(addNote.show) && (
            <div id="addnewnote-box" onClick={e => e.stopPropagation()}>
              <div id="addnewnote-header">Create a note</div>
              
              <div id="addnewnote-title">
                <div id="addnewnote-title-header">Enter the title of note</div>
                <input
                  maxLength="50"
                  onChange={(e) => setAddnote({ ...addNote, title: e.target.value })}
                  placeholder="Enter title"
                  value={addNote.title}
                  onKeyDown={e => {
                    e.stopPropagation()

                    if (e.keyCode === 13) {
                      createTheNote()
                    }
                  }}
                />
              </div>
              <div id="addnewnote-note">
                <div id="addnewnote-note-header">Enter the info of the note</div>
                <textarea 
                  multiline 
                  onChange={(e) => setAddnote({ ...addNote, info: e.target.value })}
                  placeholder="Enter note"
                  maxLength={1000}
                >
                  {addNote.info}
                </textarea>
              </div>

              <div className="row">
                <div id="addnewnote-button" onClick={() => createTheNote()}>
                  <div id="addnewnote-button-header">Create</div>
                  <div id="addnewnote-button-icon"><IoIosAdd style={{ height: '100%', width: '100%' }}/></div>
                </div>
              </div>

              <div id="errormsg">{addNote.errorMsg}</div>
            </div>
          )}
          {(deletableBox.show) && (
            <div id="deletable-box" onClick={(e) => e.stopPropagation()}>
              <div id="deletable-header">{deletableBox.errorMsg}</div>

              <div id="button-actions">
                <div className="button-action" onClick={() => setDeletablebox({ ...deletableBox, show: false, errorMsg: '' })}>Ok</div>
              </div>
            </div>
          )}
          {(deleteChoicebox.show) && (
            <div id="delete-box" onClick={e => e.stopPropagation()}>
              <div id="delete-headers">
                <div id="delete-header">{deleteChoicebox.show && 'Delete'}</div>
              </div>

              {deleteChoicebox.show && <div style={{ fontSize: 30, textAlign: 'center' }}><strong>{deleteChoicebox.type}</strong>: {deleteChoicebox.name}</div>}

              {deleteChoicebox.loading && (
                <div id="loading-box" style={{ height: 20 }}>
                  <div id="loading-container">
                    <div id="loading-icon" style={{ height: 20, width: 20 }}>
                      <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                  </div>
                </div>
              )}

              <div id="button-actions">
                <div className={"button-action" + (deleteChoicebox.loading ? "-disabled" : "")} onClick={deleteChoicebox.loading ? null : () => setDeletechoicebox({ ...deleteChoicebox, show: false, type: '', name: '' })}>Cancel</div>
                <div className={"button-action" + (deleteChoicebox.loading ? "-disabled" : "")} onClick={deleteChoicebox.loading ? null : () => {
                  if (deleteChoicebox.type === "Note") {
                    removeTheNote()
                  } else {
                    deleteTheChoice()
                  }
                }}>Yes</div>
              </div>
            </div>
          )}
          {(seeNotebox.show) && (
            <div id="seenote-box" onClick={e => e.stopPropagation()}>
              <div id="seenote-header">View / Edit note</div>
              
              <div id="seenote-title">
                <input
                  maxLength="50"
                  onChange={(e) => setSeenotebox({ ...seeNotebox, title: e.target.value })}
                  placeholder="Enter title"
                  value={seeNotebox.title}
                  onKeyDown={e => {
                    e.stopPropagation()

                    if (e.keyCode === 13) {
                      saveTheNote()
                    }
                  }}
                />
              </div>
              <div id="seenote-note">
                <textarea 
                  multiline 
                  onChange={(e) => setSeenotebox({ ...seeNotebox, info: e.target.value })}
                  placeholder="Enter note"
                >
                  {seeNotebox.info}
                </textarea>
              </div>

              <div className="row">
                <div id="seenote-buttons">
                  <div className="seenote-button" onClick={() => setSeenotebox({ ...seeNotebox, show: false, id: -1, title: '', info: '' })}>
                    <div className="seenote-button-header">Cancel Edit</div>
                    <div className="seenote-button-icon"><IoMdClose style={{ height: '100%', width: '100%' }}/></div>
                  </div>
                  <div className="seenote-button" onClick={() => saveTheNote()}>
                    <div className="seenote-button-header">Save</div>
                    <div className="seenote-button-icon"><FaCheck style={{ height: '100%', width: '100%' }}/></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(usageRecords.show) && (
            <Usagerecords 
              usageRecords={usageRecords}
              togglePlan={() => toggleThePlan()}
            />
          )}
          {(seeAllnotesbox.show) && (
            <div id="seeallnotes-box" onClick={e => e.stopPropagation()}>
              <div id="seeallnotes-header">View / Edit All Note(s)</div>

              <div id="seeallnotes-notelist">
                {seeAllnotesbox.list.map((info, index) => (
                  <div key={info.key}>
                    <div className="seeallnotes-delete" onClick={(e) => {
                      const { list } = seeAllnotesbox

                      list.splice(index, 1)

                      setSeeallnotesbox({ ...seeAllnotesbox, list })
                    }}>
                      <IoMdClose style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                    <div className="seeallnotes-title">
                      <input
                        maxLength="50"
                        onChange={(e) => {
                          const { list } = seeAllnotesbox

                          list[index].title = e.target.value

                          setSeeallnotesbox({ ...seeAllnotesbox, list })
                        }}
                        value={info.title}
                        placeholder="TITLE"
                        placeholdertextcolor="lightgrey"
                        onKeyDown={e => {
                          e.stopPropagation()
      
                          if (e.keyCode === 13) {
                            saveAllTheNote()
                          }
                        }}
                      />
                    </div>
                    <textarea 
                      multiline 
                      onChange={(e) => {
                        const { list } = seeAllnotesbox

                        list[index].info = e.target.value

                        setSeeallnotesbox({ ...seeAllnotesbox, list })
                      }}
                      placeholder="Enter note"
                      className="seeallnotes-note"
                    >
                      {info.info}
                    </textarea>
                  </div>
                ))}

                <div className="row">
                  <div id="seeallnotes-add" onClick={() => {
                    const { list } = seeAllnotesbox
                    const lastNote = list[list.length - 1]

                    list.push({
                      key: "note-" + parseInt(lastNote.key.replace("note-", "")) + 1,
                      title: '',
                      info: ''
                    })

                    setSeeallnotesbox({ ...seeAllnotesbox, list })
                  }}>
                    <div id="seeallnotes-add-header">Add Note</div>
                    <div className="column">
                      <div id="seeallnotes-add-icon"><IoIosAdd style={{ color: 'black', height: '80%', width: '80%' }}/></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div id="seeallnotes-buttons">
                  <div className="seeallnotes-button" onClick={() => setSeeallnotesbox({ ...seeAllnotesbox, show: false, list: [] })}>
                    <div className="seeallnotes-button-header">Cancel Edit</div>
                    <div className="seeallnotes-button-icon"><IoMdClose style={{ height: '100%', width: '100%' }}/></div>
                  </div>
                  <div className="seeallnotes-button" onClick={() => saveAllTheNote()}>
                    <div className="seeallnotes-button-header">Save</div>
                    <div className="seeallnotes-button-icon"><FaCheck style={{ height: '100%', width: '100%' }}/></div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(requestFeaturebox.show) && (
            <div id="request-feature-box" onClick={(e) => e.stopPropagation()}>
              <div id="request-feature-header">What feature do you want ?</div>

              <div id="request-feature-input">
                <textarea 
                  type="text" maxLength="100" 
                  onChange={(e) => setRequestfeaturebox({ ...requestFeaturebox, input: e.target.value })}
                  placeholder="Enter your opinion here"
                >{requestFeaturebox.input}</textarea>
              </div>

              <div id="request-feature-non">
                <div id="request-feature-non-header">Anonymously ?</div>
                <div className="row">
                  <div id="request-feature-non-actions">
                    <div className={"request-feature-non-action" + (requestFeaturebox.isPrivate ? "-selected" : "")} onClick={() => {
                      if (!requestFeaturebox.isPrivate) {
                        setRequestfeaturebox({ ...requestFeaturebox, isPrivate: true })
                      }
                    }}>Yes</div>
                    <div className={"request-feature-non-action" + (requestFeaturebox.isPrivate ? "" : "-selected")} onClick={() => {
                      if (requestFeaturebox.isPrivate) {
                        setRequestfeaturebox({ ...requestFeaturebox, isPrivate: false })
                      }
                    }}>No</div>
                  </div>
                </div>
              </div>

              <div id="errormsg">{requestFeaturebox.errorMsg}</div>

              <div className="row">
                <div id="button-actions">
                  <div className={"button-action" + (requestFeaturebox.loading ? "-disabled" : "")} onClick={requestFeaturebox.loading ? null : () => {
                    setRequestfeaturebox({ ...requestFeaturebox, show: false, input: '', isPrivate: true, loading: false, errorMsg: '' })
                  }}>Cancel</div>
                  <div className={"button-action" + (requestFeaturebox.loading ? "-disabled" : "")} onClick={requestFeaturebox.loading ? null : (e) => requestTheFeature()}>Send</div>
                </div>
              </div>
            </div>
          )}
          {(showLicenses) && <Licenses/>}
        </div>
      )}
      
      {(showExceedusagebox.show || moveLoading) && (
        <div id="popup-box" onClick={(e) => {
          setShowexceedusagebox({ ...showExceedusagebox, show: false })

          e.stopPropagation()
        }}>
          {(showExceedusagebox.show) && (
            <div id="exceed-usage-box" onClick={(e) => e.stopPropagation()}>
              <div className="exceed-usage-header">Wow, you have reached the standard usage limit</div>
              <div className="exceed-usage-header">We hope you've enjoying using Detach so far</div>
              <div className="exceed-usage-header">We will add more plans soon</div>
            </div>
          )}
          {moveLoading && (
            <div id="move-loading">
              <div id="loading-container" style={{ margin: '10px 0' }}>
                <div id="loading-icon" style={{ height: 30, width: 30 }}>
                  <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
