import "./header.scss";
import { BrowserView, MobileView } from "react-device-detect"

export default function Header() {
  return (
    <>
      <BrowserView>
        <div id="header-web-comp">
          <div id="header-row">
            <div id="icon-holder" onClick={() => window.location = "/"}>
              <div className="column">
                <div id="icon">
                  <img alt="icon" src="/logo.png"/>
                </div>
              </div>
              <div id="icon-headers">
                <div className="column">
                  <div id="icon-header-title">
                    DETACH
                  </div>
                  <div id="icon-header-minititle">
                    <div style={{ fontSize: 13 }}><strong style={{ fontWeight: 100 }}>MANAGE YOUR WORK <strong>ALL-ON-ONE SCREEN</strong></strong></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div id="auths">
                <div className="auth" onClick={() => window.location = "/"}>HOME</div>

                {localStorage.getItem("token") ? 
                  <>
                    <div className="auth" onClick={() => {
                      localStorage.clear()

                      window.location = "/"
                    }}>LOG-OUT</div>
                  </>
                  :
                  <>
                    <div className="auth" style={{ width: 120 }} onClick={() => window.location = "/auth"}>LOGIN/REGISTER</div>
                  </>
                }
                
                <div className="auth" onClick={() => window.location = "/pricing"}>PRICING</div>
                <div className="auth" onClick={() => window.location = "/ourmission"}>MISSION</div>
                <div className="auth" onClick={() => window.location = "/terms"}>TERMS OF SERVICE</div>
                <div className="auth" onClick={() => window.location = "/policy"}>PRIVACY POLICY</div>
              </div>
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div id="header-mobile-comp">
          <div id="icon-holder" onClick={() => window.location = "/"}>
            <div id="icon">
              <img alt="icon" src="/logo.png"/>
            </div>
            <div id="icon-header">
              <div id="icon-header-title">
                DETACH
              </div>
              <div id="icon-header-minititle">
                <strong style={{ fontWeight: 100 }}>
                  MANAGE YOUR WORK<br/>
                  <strong>ALL-ON-ONE SCREEN</strong>
                </strong>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="row">
              <div className="auths">
                <div className="auth" onClick={() => window.location = "/"}>HOME</div>
                <div className="auth" onClick={() => window.location = "/pricing"}>PRICING</div>
              </div>
            </div>
            <div className="row">
              <div className="auths">
                <div className="auth" onClick={() => window.location = "/ourmission"}>MISSION</div>
                <div className="auth" onClick={() => window.location = "/terms"}>TERMS OF SERVICE</div>
                <div className="auth" onClick={() => window.location = "/policy"}>PRIVACY POLICY</div>
              </div>
            </div>
          </div>
        </div>
      </MobileView>
    </>
  )
}
